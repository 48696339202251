import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

export const getMuiContainerOverrides = (theme: Theme): ComponentsOverrides['MuiContainer'] => ({
  root: {
    padding: theme.spacing(0, 1.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
});

export const getMuiContainerDefaultProps = (): ComponentsProps['MuiContainer'] => ({
  maxWidth: 'xl',
  sx: {
    mx: 'auto',
  },
});
