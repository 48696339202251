import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Twitter: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 19"
    sx={{
      width: '19px',
      height: '19px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M7.39337 2.87915H3.61328L12.4231 15.4294H16.1721L7.39337 2.87915ZM5.09195 3.65209H6.99755L14.6963 14.6564H12.8189L5.09195 3.65209Z"
      fill="white"
    />
    <path d="M15.8386 2.87061H14.883L10.7156 7.63706L11.1623 8.27545L15.8386 2.87061Z" fill="white" />
    <path d="M3.896 15.4295H4.84879L9.08973 10.6602L8.64302 10.0247L3.896 15.4295Z" fill="white" />
  </SvgIcon>
);
