import { Box, Stack, StackProps, Typography } from '@mui/material';
import { tokenLogoImg } from 'assets';

type Size = 'small' | 'medium';

const sizesConfig = {
  small: {
    image: {
      size: 20,
    },
    typography: {
      fontSize: '18px',
    },
  },
  medium: {
    image: {
      size: 24,
    },
    typography: {
      fontSize: '20px',
    },
  },
} as const;

interface TokenAmountProps extends StackProps {
  amount: number | string;
  size?: Size;
}

export const TokenAmount = ({ amount, size = 'small', ...stackProps }: TokenAmountProps) => {
  const { image, typography } = sizesConfig[size];
  return (
    <Stack direction="row" spacing={1} alignItems="center" {...stackProps}>
      <Box component="img" src={tokenLogoImg} alt="token" width={image.size} height={image.size} />
      <Typography {...typography}>{amount}</Typography>
    </Stack>
  );
};
