import { IconButton, Stack, SxProps, Theme, useTheme } from '@mui/material';
import { Copyable, Icon } from 'components';
import { shortenPhrase } from 'lib';
import { COLOR_ACCENT, COLOR_BLUE_DUSK, COLOR_GRAY_MID } from 'styles';

interface AccountProps {
  onDisconnect: () => void;
  address: string;
  shortenAddressParams?: {
    start?: number;
    end?: number;
  };
  sx?: SxProps<Theme>;
}

export const Account = ({ onDisconnect, address, shortenAddressParams, sx }: AccountProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      overflow="hidden"
      sx={{
        maxWidth: 181,
        ...sx,
      }}
    >
      <Copyable
        displayText={shortenPhrase(address, 4, 4)}
        copyText={address}
        sx={{
          padding: theme.spacing(1, 1.25, 1, 1.75),
          border: '2px solid',
          borderColor: COLOR_BLUE_DUSK,
          borderRadius: '67px 0 0 67px',
          minWidth: { xxs: 145, xs: 135, sm: 145 },
          '* > svg': {
            color: COLOR_BLUE_DUSK,
          },
        }}
      />
      <IconButton
        onClick={onDisconnect}
        sx={{
          padding: theme.spacing(0.75, 1, 0.75, 0.5),
          background: COLOR_BLUE_DUSK,
          minWidth: 36,
          color: COLOR_GRAY_MID,
          borderRadius: '0px 67px 67px 0px',
          '&:hover': {
            background: COLOR_BLUE_DUSK,
            color: `${COLOR_ACCENT} !important`,
          },
        }}
      >
        <Icon.Exit />
      </IconButton>
    </Stack>
  );
};
