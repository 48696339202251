import { forwardRef } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export interface ButtonProps extends MuiButtonProps {
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ width, maxWidth, height, minWidth, ...rest }, ref) => {
    return <MuiButton ref={ref} {...rest} sx={{ width, maxWidth, height, minWidth, ...rest.sx }} />;
  },
);

Button.displayName = 'Button';
