import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { COLOR_ACCENT } from 'styles';

export interface SpinnerProps {
  size?: number;
}

export const Spinner: FC<SpinnerProps> = ({ size = 100 }) => {
  return <CircularProgress size={size} thickness={3} sx={{ color: COLOR_ACCENT }} />;
};
