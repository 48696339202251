import { useEffect, useRef, useState } from 'react';
import { Skeleton, Stack, styled } from '@mui/material';
import { BORDER_RADIUS_L, COLOR_BLUE_DUSK, PaperBox } from 'styles';

export const FormBox = styled(Stack)(({ theme }) => ({
  maxWidth: 506,
  width: '100%',
  padding: theme.spacing(1.5),
  border: `2px solid ${COLOR_BLUE_DUSK}`,
  borderRadius: BORDER_RADIUS_L,
}));

export const VestingWidgetSkeleton = () => {
  return (
    <FormBox mx="auto" spacing={0.75}>
      <Stack direction="row" width="100%" spacing={0.75}>
        <PaperBox>
          <Skeleton variant="rounded" sx={{ minHeight: 62 }} />
        </PaperBox>
        <PaperBox>
          <Skeleton variant="rounded" sx={{ minHeight: 62 }} />
        </PaperBox>
      </Stack>

      <PaperBox sx={{ aspectRatio: '1.55', height: '100%' }}>
        <Skeleton variant="rounded" height="100%" />
      </PaperBox>
    </FormBox>
  );
};

interface UseVestingStateProps {
  startTimestampSec: number;
  endTimestampSec: number;
  nextClaimTimestampSec: number;
  onUpdateData: () => void;
  vestingPeriodSec: number;
}

export const useVestingState = ({
  startTimestampSec,
  endTimestampSec,
  nextClaimTimestampSec,
  onUpdateData,
  vestingPeriodSec,
}: UseVestingStateProps) => {
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const currentDateTimestamp = useRef<number | null>(null);

  const [isVestingOver, setVestingOver] = useState(false);
  const [isVestingStarted, setVestingStarted] = useState(false);
  const [timerTimeStamp, setTimerTimestamp] = useState(0);

  useEffect(() => {
    const stopUiUpdater = () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    };

    const uiUpdaterFunction = () => {
      currentDateTimestamp.current = Date.now() / 1000;

      if (currentDateTimestamp.current > +endTimestampSec) {
        stopUiUpdater();
        setVestingOver(true);
      }

      if (currentDateTimestamp.current >= +startTimestampSec) {
        setVestingStarted(true);
        setTimerTimestamp(+nextClaimTimestampSec);
      }
      
      if (currentDateTimestamp.current < +startTimestampSec) {
        // Changed to always show next claim date
        // setTimerTimestamp(+startTimestampSec);
        setTimerTimestamp(+nextClaimTimestampSec);
      }

      if (Math.floor(currentDateTimestamp.current) === +nextClaimTimestampSec) {
        onUpdateData?.();
      }
    };

    timerIdRef.current = setInterval(() => {
      uiUpdaterFunction();
    }, 1000);

    return () => {
      stopUiUpdater();
    };
  }, [endTimestampSec, nextClaimTimestampSec, onUpdateData, startTimestampSec, vestingPeriodSec]);

  return {
    isVestingOver,
    isVestingStarted,
    timerEndTimeStamp: timerTimeStamp,
    timerStartTimeStamp: currentDateTimestamp.current,
  };
};
