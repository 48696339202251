import { ComponentProps, FC, forwardRef } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { CHAIN_TYPE } from 'app-constants';
// import { knineLogoSvg, tokenLogoImg } from 'assets';
import { Button, Icon } from 'components';
import { contracts, ContractsNames } from 'services';
import { COLOR_WH } from 'styles';

export const AddTokenButton: FC<ComponentProps<typeof Button>> = forwardRef((props, ref) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = async () => {
    // alert('Add KNINE token to your wallet');
    const chainType = CHAIN_TYPE ?? 'testnet';
    const tokenAddress = contracts[ContractsNames.K9Token][chainType].address.Ethereum;
    const tokenSymbol = 'KNINE';
    const tokenDecimals = 18;
    const tokenImage = 'https://vesting.k9finance.com/KNINE-token-logo.png';

    const opts = {
      // The address of the token.
      address: tokenAddress,
      // A ticker symbol or shorthand, up to 5 characters.
      symbol: tokenSymbol,
      // The number of decimals in the token.
      decimals: tokenDecimals,
      // A string URL of the token logo.
      image: tokenImage,
    };
    // alert(`opts: ${  JSON.stringify(opts)}`);

    try {
      // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
      const wasAdded = await window.ethereum?.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: opts,
        },
      });

      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      {...props}
      ref={ref}
      {...(isSmScreen && {
        minWidth: 'auto',
        width: 'fit-content',
        sx: { borderRadius: '50%', padding: theme.spacing(0.5, 0.35), mr: 1 },
      })}
      onClick={handleClick}
    >
      {isSmScreen ? (
        <Icon.MetamaskWhite2 sx={{ color: COLOR_WH, width: '38px', height: '38px' }} />
      ) : (
        <>
          {/* <Stack flex={1} /> */}
          <Icon.MetamaskWhite2 sx={{ color: COLOR_WH, width: '42px', height: '42px' }} />
          <Stack flex={1} />
          Add KNINE
          <Stack flex={1} />
        </>
      )}
    </Button>
  );
});

AddTokenButton.displayName = 'AddTokenButton';
