import { CSSProperties } from '@mui/material/styles/createTypography';

export type FlexHelperProps = {
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexDirection?: CSSProperties['flexDirection'];
};

export const flexHelper = ({
  flexDirection = 'row',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
}: FlexHelperProps) => ({
  display: 'flex',
  justifyContent,
  alignItems,
  flexDirection,
});
