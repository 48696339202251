import { createAppAsyncThunk, fromDecimals, logger } from 'lib';
import { ContractsNames, ContractsService, getTokenInfo } from 'services';
import type { RequestWithWeb3Provider } from 'types';

import actionTypes from '../action-types';

export const getTokenBalance = createAppAsyncThunk(
  actionTypes.GET_TOKEN_BALANCE,
  async ({ web3Provider }: RequestWithWeb3Provider, { rejectWithValue, getState }) => {
    try {
      const { network, chainType, address: userAddress } = getState().user;

      const { address: tokenAddress } = ContractsService.getContractData(ContractsNames.K9Token, network, chainType);

      const tokenContract = new ContractsService(web3Provider).getErc20Contract(tokenAddress);

      const balance = await tokenContract.methods.balanceOf(userAddress).call();

      return fromDecimals(balance, getTokenInfo(network, ContractsNames.K9Token, chainType).decimals);
    } catch (error: unknown) {
      logger('getTokenBalance', error);
      return rejectWithValue(error);
    }
  },
);
