import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Divider, Stack, Typography } from '@mui/material';
import { routesConfig } from 'app-constants';
import { tokenLogoImg } from 'assets';
import { useShallowSelector } from 'lib';
import { useWalletConnectorContext } from 'services';
import { openModal } from 'store/modals';
import { selectIsAuthenticated } from 'store/user';
import { Modals } from 'types';

import { Account, AddTokenButton, ConnectWalletButton } from './components';

export const Header = () => {
  const dispatch = useDispatch();

  const { isAuthenticated, address } = useShallowSelector(selectIsAuthenticated);
  const { disconnect } = useWalletConnectorContext();

  const handleOpen = () => {
    dispatch(
      openModal({
        open: true,
        activeModal: Modals.ConnectWallet,
      }),
    );
  };

  return (
    <Stack width="100%">
      <Stack direction="row" alignItems="center" justifyContent="space-between" py={1.5}>
        <Stack direction="row" alignItems="center" spacing={{ xxs: 0.5, sm: 1 }}>
          <NavLink to={routesConfig.home.root} style={{ lineHeight: 0 }}>
            <img src={tokenLogoImg} alt="logo" width={36} height={36} />
          </NavLink>
          <Typography
            className="semi-bold secondary"
            textTransform="uppercase"
            whiteSpace="nowrap"
            display={{ xxs: 'none', xs: 'block' }}
          >
            K9 Finance
          </Typography>
        </Stack>
        <Stack flex={1} />

        <AddTokenButton maxWidth={160} width="100%" height={44} sx={{ mr: 2 }} />
        {isAuthenticated ? (
            <Account
              onDisconnect={disconnect}
              address={address}
              shortenAddressParams={{ start: 6, end: 4 }}
              sx={{ ml: { xs: 0.75, sm: 1.25 } }}
            />
        ) : (
          <ConnectWalletButton maxWidth={160} width="100%" height={44} onClick={handleOpen} />
        )}
      </Stack>

      <Divider />
    </Stack>
  );
};
