import { Divider, Stack, Typography } from '@mui/material';
import { formatTimestampToDateString } from 'lib';
import { PaperBox } from 'styles';

interface ClaimDatesProps {
  claimEndTimestamp: string;
  nextClaimTimestamp: string;
  availableToClaim: string;
  isEnded: boolean;
}

export const ClaimDates = ({ claimEndTimestamp, nextClaimTimestamp, availableToClaim, isEnded }: ClaimDatesProps) => {
  const isTokensBeenClaimed = isEnded && +availableToClaim === 0;

  return (
    <PaperBox paddingPx={12} direction="row" spacing={3} minHeight={70} alignItems="center" justifyContent="center">
      {isTokensBeenClaimed && <Typography textAlign="center">All your tokens have been claimed</Typography>}

      {!isTokensBeenClaimed && (
        <>
          <Stack spacing={1} alignItems="center" flex={1}>
            <Typography variant="caption" className="neutral medium">
              Next Claim Date
            </Typography>
            <Typography>{formatTimestampToDateString(nextClaimTimestamp)}</Typography>
          </Stack>

          <Divider orientation="vertical" className="secondary" sx={{ minHeight: '44px' }} />

          <Stack spacing={1} alignItems="center" flex={1}>
            <Typography variant="caption" className="neutral medium">
              Final Claim Date
            </Typography>
            <Typography>{formatTimestampToDateString(claimEndTimestamp)}</Typography>
          </Stack>
        </>
      )}
    </PaperBox>
  );
};
