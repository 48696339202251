import type { AbiItem } from 'web3-utils';

import { type ChainType, Chains } from './chains';

export enum ContractsNames {
  K9Token = 'K9Token',
  Vesting = 'Vesting',
}

export interface IContracts {
  decimals: number;
  names: string[];
  contracts: {
    [contractName in ContractsNames]: {
      [chainType in ChainType]: {
        address: {
          [chainKey in Chains]: string;
        };
        abi: AbiItem[];
      };
    };
  };
}
