import { UnknownAction } from '@reduxjs/toolkit';
import type { FulfilledAction, LifeCycleState, PendingAction, RejectedAction } from 'types';

export const initialLyfecycleState = {};

export const execActionName = (actionTypeStr: string) => actionTypeStr.split('/')[0];

export const updateLifecycleState = <State extends LifeCycleState>(
  state: State,
  action: RejectedAction | FulfilledAction | PendingAction,
) => {
  return {
    ...state,
    lifecycleState: {
      ...state.lifecycleState,
      [execActionName(action.type)]: action.meta.requestStatus,
    },
  };
};

export function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}

export function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}

export function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
