export const routesConfig = {
  landing: {
    root: '/',
    title: 'Home',
  },
  home: {
    root: 'https://k9finance.com/',
    title: 'Home',
  },
};
