import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modals, ModalsInitialState, ModalState } from 'types';

const initialState: ModalsInitialState = {
  modalState: {
    activeModal: Modals.init,
    txHash: '',
    open: false,
  },
};

export const modalsReducer = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<Partial<ModalState>>) => ({
      ...state,
      modalState: {
        ...state.modalState,
        ...action.payload,
      },
    }),

    closeModal: (state) => ({
      ...state,
      modalState: {
        activeModal: Modals.init,
        txHash: '',
        open: false,
      },
    }),
  },
  selectors: {
    getModals: (state) => state.modalState,
    getProp: <T extends keyof ModalsInitialState>(state: ModalsInitialState, propKey: T) => state[propKey],
  },
});

export const { openModal, closeModal } = modalsReducer.actions;

export const { getModals, getProp } = modalsReducer.selectors;

export default modalsReducer.reducer;
