import { INoNameContract } from '@amfi/connect-wallet/src/interface';
import { Erc20Abi, VestingAbi } from 'types';
import Web3 from 'web3';

import { erc20Abi } from '../abi';
import { Chains, ChainType, ContractsNames } from '../types';

import { contracts } from './contracts';

export class ContractsService {
  private provider: Web3;

  constructor(provider: Web3) {
    this.provider = provider;
  }

  public getErc20Contract(tokenAddress: string) {
    return new this.provider.eth.Contract(erc20Abi, tokenAddress) as unknown as Erc20Abi;
  }

  public static getContractData(contractName: ContractsNames, network: Chains, chainType: ChainType): INoNameContract {
    const { abi, address } = contracts[contractName][chainType];
    return {
      address: address[network],
      abi,
    };
  }

  public getVestingContract(network: Chains, chainType: ChainType) {
    const { address, abi } = ContractsService.getContractData(ContractsNames.Vesting, network, chainType);

    return new this.provider.eth.Contract(abi, address) as unknown as VestingAbi;
  }
}
