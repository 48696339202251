import { useEffect, useMemo, useRef, useState } from 'react';

export type DateLike = string | number | Date;

export const MILLISECOND = 1;
export const SECOND = 1000 * MILLISECOND;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

const calculateTimeLeft = (startTime: DateLike, endTime: DateLike) => {
  const dateEndTime = new Date(endTime);
  const difference = +dateEndTime - +new Date(startTime);

  let ret = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    ret = {
      days: Math.floor(difference / DAY),
      hours: Math.floor(difference / HOUR) % 24,
      minutes: Math.floor((difference / MINUTE) % 60),
      seconds: Math.floor((difference / SECOND) % 60),
    };
  }

  return ret;
};

/**
 * Calculates time left from endTime to now (start time)
 */
export const useTimeLeft = (endTime: DateLike, startTime = Date.now()) => {
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const diff = useMemo(() => +new Date(endTime) - +new Date(startTime), [endTime, startTime]);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(diff);
  }, [endTime, startTime, diff]);

  useEffect(() => {
    const stopTimer = () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
        timerIdRef.current = null;
      }
    };

    const tick = () => {
      setCounter((prevCounter) => {
        const updatedCounter = prevCounter - 1000;
        return updatedCounter || 0;
      });
    };

    const startTimer = () => {
      stopTimer();
      timerIdRef.current = setInterval(tick, 1000);
    };

    if (counter > 0) {
      startTimer();
    }

    return () => {
      stopTimer();
    };
  }, [counter, endTime]);

  const dateNow = Date.now();

  return calculateTimeLeft(dateNow, dateNow + counter);
};
