import { TypographyOptions } from '@mui/material/styles/createTypography';

import {
  COLOR_ACCENT,
  COLOR_BLUE_ETHEREAL,
  COLOR_GRAY,
  COLOR_LIGHT,
  COLOR_RED,
  COLOR_WH,
  FontFamilies,
  FontWeights,
  GRADIENT_ACCENT,
} from '../variables';

export const colorVariations = {
  '&.white': {
    color: COLOR_WH,
  },
  '&.red': {
    color: COLOR_RED,
  },
  '&.neutral': {
    color: COLOR_BLUE_ETHEREAL,
  },
  '&.light': {
    color: COLOR_LIGHT,
  },
  '&.accent': {
    color: COLOR_ACCENT,
  },
  '&.gradient': {
    background: GRADIENT_ACCENT,
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',

    WebkitTextFillColor: 'transparent',
  },
  '&.gray': {
    color: COLOR_GRAY,
  },
};

export const weightVariations = {
  '&.semi-bold': {
    fontWeight: FontWeights.SemiBold,
  },
  '&.medium': {
    fontWeight: FontWeights.Medium,
  },
  '&.extra-bold': {
    fontWeight: FontWeights.ExtraBold,
  },
};

export const familyVariations = {
  '&.primary': {
    fontFamily: FontFamilies.primary,
  },
  '&.secondary': {
    fontFamily: FontFamilies.secondary,
  },
  '&.tertiary': {
    fontFamily: FontFamilies.tertiary,
  },
};

export const customClasses = {
  '&.truncate': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

export const getTypographyOptions = (): TypographyOptions => ({
  fontFamily: FontFamilies.primary,

  allVariants: {
    color: COLOR_WH,
    fontWeight: FontWeights.Regular,
    textAlign: 'left',
    fontStyle: 'normal',
    lineHeight: '24px',
    ...colorVariations,
    ...weightVariations,
    ...familyVariations,
    ...customClasses,
  },

  h1: {
    fontSize: '36px',
    lineHeight: '36px',
  },

  h2: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: FontWeights.ExtraBold,
  },

  h3: {
    fontSize: '18px',
    lineHeight: '28px',
  },

  h4: {
    fontSize: '20px',
    lineHeight: '30px',
  },

  body1: {
    fontSize: '16px',
  },

  body2: {
    fontSize: '14px',
    lineHeight: '20px',
  },

  caption: {
    fontSize: '13px',
    lineHeight: '16px',
  },

  button: {
    fontSize: '15px',
    lineHeight: '30px',
  },
});
