import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const OpenInNew: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M0 0h24v24H0V0z" //
      fill="none"
      // stroke="currentColor"
      // strokeWidth="2"
      // strokeLinejoin="round"
    />
    <path
      fill="currentColor"
      // stroke="currentColor"
      // strokeWidth="2"
      // strokeLinejoin="round"
      d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1z"
    />
  </SvgIcon>
);
