import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Metamask: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 42 42"
    sx={{
      width: '42px',
      height: '42px',
      fill: 'none',
      ...sx,
    }}
  >
    <rect width="42" height="42" rx="12" fill="#2D344B" />
    <path
      d="M33.6158 8.0061L22.675 16.13L24.7072 11.337L33.6158 8.0061Z"
      fill="#EE6E21"
      stroke="#EE6E21"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.38379 8.00604L19.2257 16.2069L17.2924 11.337L8.38379 8.00604Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6832 26.8373L26.7722 31.3005L33.0005 33.0154L34.7801 26.9362L29.6832 26.8373Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.23047 26.9362L8.99901 33.0154L15.2273 31.3005L12.3274 26.8373L7.23047 26.9362Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.887 19.296L13.1514 21.9234L19.3248 22.1982L19.1161 15.5474L14.887 19.296Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1126 19.296L22.8176 15.4704L22.6748 22.1982L28.8482 21.9234L27.1126 19.296Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2273 31.3006L18.9511 29.4867L15.7436 26.9803L15.2273 31.3006Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0483 29.4867L26.7722 31.3006L26.2559 26.9803L23.0483 29.4867Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.7724 31.3005L23.0486 29.4866L23.3452 31.9161L23.3122 32.9385L26.7724 31.3005Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2275 31.3009L18.6877 32.9388L18.6658 31.9165L18.9514 29.487L15.2275 31.3009Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7537 25.3753L15.656 24.4628L17.842 23.4625L18.7537 25.3753Z"
      fill="#233447"
      stroke="#233447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2463 25.3753L24.1581 23.4625L26.355 24.4628L23.2463 25.3753Z"
      fill="#233447"
      stroke="#233447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2276 31.3005L15.7659 26.8373L12.3276 26.9362L15.2276 31.3005Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2344 26.8373L26.7726 31.3005L29.6836 26.9362L26.2344 26.8373Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8485 21.9234L22.675 22.1982L23.2463 25.3752L24.158 23.4624L26.3549 24.4628L28.8485 21.9234Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6561 24.4628L17.8421 23.4624L18.7538 25.3752L19.325 22.1982L13.1516 21.9234L15.6561 24.4628Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1514 21.9236L15.7438 26.9804L15.6559 24.463L13.1514 21.9236Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.3547 24.4628L26.2559 26.9802L28.8483 21.9233L26.3547 24.4628Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3246 22.1979L18.7534 25.3749L19.4784 29.1235L19.6322 24.1876L19.3246 22.1979Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6745 22.1978L22.3779 24.1765L22.5207 29.1234L23.2457 25.3748L22.6745 22.1978Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2465 25.3752L22.5215 29.1239L23.0487 29.4866L26.2563 26.9802L26.3551 24.4628L23.2465 25.3752Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.656 24.4628L15.7439 26.9802L18.9514 29.4866L19.4787 29.1239L18.7537 25.3752L15.656 24.4628Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3123 32.9385L23.3452 31.9162L23.0706 31.6743H18.9294L18.6658 31.9162L18.6877 32.9385L15.2275 31.3005L16.4359 32.2899L18.8964 33.9939H23.1036L25.5641 32.2899L26.7725 31.3005L23.3123 32.9385Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0487 29.4867L22.5214 29.124H19.4786L18.9514 29.4867L18.6658 31.9162L18.9294 31.6744H23.0706L23.3453 31.9162L23.0487 29.4867Z"
      fill="#161616"
      stroke="#161616"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.0772 16.6576L34.9999 12.1724L33.6159 8.00604L23.0486 15.8551L27.1129 19.296L32.8579 20.9779L34.1212 19.4939L33.5719 19.0981L34.4507 18.2956L33.7806 17.7679L34.6594 17.0974L34.0772 16.6576Z"
      fill="#763D16"
      stroke="#763D16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12.1726L7.9337 16.6578L7.34053 17.0975L8.2193 17.7681L7.54924 18.2958L8.42801 19.0983L7.87878 19.494L9.14202 20.9781L14.887 19.2962L18.9514 15.8553L8.38407 8.00623L7 12.1726Z"
      fill="#763D16"
      stroke="#763D16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.8577 20.9778L27.1127 19.2959L28.8482 21.9233L26.2559 26.9801L29.6831 26.9361H34.78L32.8577 20.9778Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8868 19.2959L9.14181 20.9778L7.23047 26.9361H12.3274L15.7436 26.9801L13.1512 21.9233L14.8868 19.2959Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.675 22.1983L23.0485 15.8553L24.7072 11.3371H17.2925L18.9512 15.8553L19.3247 22.1983L19.4675 24.1991L19.4784 29.124H22.5212L22.5322 24.1991L22.675 22.1983Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
