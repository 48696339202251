import { Icon, Spinner } from 'components';
import { TransactionModals } from 'types';

import { TransactionModalData } from './transaction-modal.types';

const baseIconSize = { width: 100, height: 100 };

export const transactionModalData: Partial<TransactionModalData> = {
  [TransactionModals.ApprovePending]: {
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    icon: <Spinner size={baseIconSize.width} />,
    body1: 'Please approve transaction in wallet.',
    body2:
      'Tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf.The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  },

  [TransactionModals.ApproveRejected]: {
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    icon: <Icon.Warn sx={baseIconSize} />,
    body1: 'You rejected the Approval transaction in your wallet. Press Approve again to start over or close this window.',
    body2:
      'Tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
    buttonText: 'Approve again',
  },

  [TransactionModals.SendPending]: {
    title1: 'STEP 2/2',
    title2: 'CONFIRM',
    icon: <Spinner size={baseIconSize.width} />,
    body1: 'Please confirm transaction in wallet',
    body2: 'It takes some time for transaction to get confirmed after you submit.',
  },
  [TransactionModals.SendSuccess]: {
    title1: 'DONE',
    title2: 'SENT',
    icon: <Icon.Success sx={baseIconSize} />,
    body1: 'Sent',
    body2: (
      <>
        Please wait for your transaction <br /> to get confirmed.
      </>
    ),
    isCopyable: true,
  },

  [TransactionModals.SendRejected]: {
    title1: 'STEP 2/2',
    title2: 'CONFIRM',
    icon: <Icon.Warn sx={baseIconSize} />,
    body1: 'Transaction rejected. Please send again or close this window to go back.',
    buttonText: 'Send again',
  },
};
