import { Box, styled } from '@mui/material';
import { flexHelper } from 'lib';
import { BORDER_RADIUS_DEFAULT } from 'styles';

export const CountdownCell = styled(Box, { shouldForwardProp: (prop) => prop !== 'isFirst' })<{ isFirst?: boolean }>(
  ({ theme }) => ({
    ...flexHelper({ alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }),
    border: '2px solid #3E455E',
    minWidth: 65,
    width: '100%',
    height: 65,
    padding: theme.spacing(0.75, 1),
    flex: 1,
    borderRadius: BORDER_RADIUS_DEFAULT,

    '& > *': {
      textAlign: 'center',
    },
  }),
);

export const formatTimeCell = (value: number) => (value < 10 ? `0${value}` : value);
