import { memo, useRef } from 'react';
import { Box, Menu, MenuItem, MenuProps, SxProps, Typography } from '@mui/material';
import { Button, Icon } from 'components';
import { BORDER_RADIUS_XXS } from 'styles';

export type CurrencyOption = {
  name: string;
  imgSrc: string;
};

export interface SelectProps extends MenuProps {
  currentOption: CurrencyOption;
  options: CurrencyOption[];
  onOpenMenu: () => void;
  onSelectCurrency: (value: CurrencyOption) => void;
}

export const CurrencySelect = memo(
  ({
    currentOption,
    options,
    onOpenMenu,
    onSelectCurrency,
    onClose,
    open,
    btnSx,
    ...rest
  }: SelectProps & { btnSx?: SxProps }) => {
    const menuRef = useRef(null);

    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          ref={menuRef}
          endIcon={<Icon.ChevronRight />}
          onClick={onOpenMenu}
          minWidth={66}
          height={28}
          sx={{
            borderRadius: BORDER_RADIUS_XXS,
            padding: 0.75,
            ...btnSx,
          }}
        >
          {currentOption.name}
        </Button>

        <Menu
          open={open}
          onClose={onClose}
          anchorEl={menuRef.current}
          {...rest}
          slotProps={{ paper: { sx: { width: 152 } } }}
          sx={{
            ...rest?.sx,
          }}
        >
          {options.map(({ name, imgSrc }) => (
            <MenuItem value={name} key={name} onClick={() => onSelectCurrency({ name, imgSrc })}>
              <Box component="img" src={imgSrc} alt={name} width={24} height={24} mr={1} />
              <Typography>{name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);

CurrencySelect.displayName = 'CurrencySelect';
