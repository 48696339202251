import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { VestingState } from 'types';

import {
  initialLyfecycleState,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  updateLifecycleState,
} from '../root';

import { getVestingInfo } from './thunks';

const initialState: VestingState = {
  totalClaimed: '',
  toBeClaimed: '',
  totalVested: '',
  availableToClaim: '',
  nextClaim: '',
  start: '',
  end: '',
  vestingPeriod: '',
  isUserInVesting: false,
  lifecycleState: initialLyfecycleState,
};

export const vestingSlice = createSlice({
  name: 'vesting',
  initialState,
  reducers: {
    updateVestingState: (state: VestingState, action: PayloadAction<Partial<VestingState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearVestingState: () => ({
      ...initialState,
    }),
  },

  selectors: {
    getVestingState: (state: VestingState) => state,
    getVestingLoadingState: (state) => state.lifecycleState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getVestingInfo.fulfilled, (state: VestingState, { payload }) => ({
        ...state,
        ...payload,
      }))
      .addMatcher(isPendingAction, updateLifecycleState)
      .addMatcher(isFulfilledAction, updateLifecycleState)
      .addMatcher(isRejectedAction, updateLifecycleState);
  },
});

export const { updateVestingState, clearVestingState } = vestingSlice.actions;
export const { getVestingState, getVestingLoadingState } = vestingSlice.selectors;

export default vestingSlice.reducer;
