type Format = 'ttf' | 'otf' | 'woff';

interface BuilderProps {
  fontFamily: string;
  path: string;
  fontWeight: number;
  fontStyle: string;
  format: Format;
}

export function buildFontFaceRule({ fontFamily, path, fontWeight, fontStyle, format }: BuilderProps) {
  const fontFormat = format === 'woff' ? format : 'truetype';

  return {
    '@font-face': {
      fontFamily,
      fontStyle,
      fontWeight,
      src: `url(${path}) format(${fontFormat})`,
    },
  };
}
