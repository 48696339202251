import { Box, Typography } from '@mui/material';
import { useShallowSelector } from 'lib';
import { actionTypes, getVestingLoadingState } from 'store/vesting';
import { LifecycleStatuses } from 'types';

import { VestingWidget, VestingWidgetSkeleton } from './containers';

export const Landing = () => {
  const vestingLoadingState = useShallowSelector(getVestingLoadingState);

  const isLoading = vestingLoadingState[actionTypes.GET_VESTING_INFO] === LifecycleStatuses.pending;

  return (
    <Box mt={6} mx="auto" width="100%" pb={{ xxs: 8, md: 12 }}>
      <Typography variant="h2" mx="auto" width="fit-content" textAlign="center" className="gradient secondary" mb={3}>
        Vesting
      </Typography>
      {isLoading && <VestingWidgetSkeleton />}

      {!isLoading && <VestingWidget />}
    </Box>
  );
};
