// ===== Border-Radius
export const BORDER_RADIUS_XXS = '4px';
export const BORDER_RADIUS_XS = '6px';
export const BORDER_RADIUS_DEFAULT = '8px';
export const BORDER_RADIUS_S = '12px';
export const BORDER_RADIUS_M = '16px';
export const BORDER_RADIUS_L = '20px';
export const BORDER_RADIUS_XL = '24px';
export const BORDER_RADIUS_XXL = '32px';
export const BORDER_RADIUS_XXXL = '62px';
export const BORDER_RADIUS_PROGRESS_BAR = '135px';

export const BORDER_RADIUS_SWIPE_BUTTON = '57px';

// ===== Transitions
export const TRANSITION_DEFAULT_TIME = 'all 0.25s ease-in-out';
export const TRANSITION_BEZIER_DEFAULT = 'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

// ===== Box-Shadow
export const SHADOW_DEFAULT = '0px 4px 10px 0px rgba(252, 109, 6, 0.32)';
export const SHADOW_GRAY = '0px 4px 8px 0px rgba(0, 0, 0, 0.25)';
