import { createTheme } from '@mui/material/styles';

import {
  getMuiButtonDefaultProps,
  getMuiButtonOverrides,
  getMuiIconButtonDefaultProps,
  getMuiIconButtonOverrides,
} from './button';
import { getMuiContainerDefaultProps, getMuiContainerOverrides } from './container';
import { getMuiCssBaselineDefaultProps, getMuiCssBaselineOverrides } from './css-baseline';
import { getMuiDividerOverrides } from './divider';
import { breakpointOptions, getMuiGridDefaultProps, getMuiGridOverrides } from './grid';
import { getMuiLinkDefaultProps, getMuiLinkOverrides } from './link';
import { getMuiMenuItemDefaultProps, getMuiMenuItemOverrides, getMuiMenuOverrides } from './menu';
import { getMuiPopoverDefaultProps, getMuiPopoverOverrides } from './popover';
import {
  getMuiFilledInputDefaultProps,
  getMuiFilledInputOverrides,
  getMuiTextFieldDefaultProps,
  getMuiTextFieldOverrides,
} from './textfield';
import { getMuiTooltipDefaultProps, getMuiTooltipOverrides } from './tooltip';
import { getTypographyOptions } from './typography';

const themeBase = createTheme({
  breakpoints: breakpointOptions,
  typography: getTypographyOptions(),
});

export const theme = createTheme(themeBase, {
  components: {
    MuiGrid: {
      defaultProps: getMuiGridDefaultProps(),
      styleOverrides: getMuiGridOverrides(),
    },
    MuiContainer: {
      defaultProps: getMuiContainerDefaultProps(),
      styleOverrides: getMuiContainerOverrides(themeBase),
    },
    MuiButton: {
      defaultProps: getMuiButtonDefaultProps(),
      styleOverrides: getMuiButtonOverrides(themeBase),
    },
    MuiIconButton: {
      defaultProps: getMuiIconButtonDefaultProps(),
      styleOverrides: getMuiIconButtonOverrides(themeBase),
    },
    MuiLink: {
      defaultProps: getMuiLinkDefaultProps(),
      styleOverrides: getMuiLinkOverrides(themeBase),
    },
    MuiTooltip: {
      defaultProps: getMuiTooltipDefaultProps(),
      styleOverrides: getMuiTooltipOverrides(themeBase),
    },
    MuiTextField: {
      defaultProps: getMuiTextFieldDefaultProps(),
      styleOverrides: getMuiTextFieldOverrides(themeBase),
    },
    MuiDivider: {
      styleOverrides: getMuiDividerOverrides(themeBase),
    },
    MuiCssBaseline: {
      defaultProps: getMuiCssBaselineDefaultProps(),
      styleOverrides: getMuiCssBaselineOverrides(themeBase),
    },
    MuiPopover: {
      defaultProps: getMuiPopoverDefaultProps(),
      styleOverrides: getMuiPopoverOverrides(themeBase),
    },
    MuiMenu: {
      styleOverrides: getMuiMenuOverrides(themeBase),
    },
    MuiMenuItem: {
      styleOverrides: getMuiMenuItemOverrides(themeBase),
      defaultProps: getMuiMenuItemDefaultProps(),
    },
    MuiFilledInput: {
      defaultProps: getMuiFilledInputDefaultProps(),
      styleOverrides: getMuiFilledInputOverrides(themeBase),
    },
  },
});
