export enum TransactionModals {
  ApprovePending = 'ApprovePending',
  ApproveRejected = 'ApproveRejected',
  SendPending = 'SendPending',
  SendRejected = 'SendRejected',
  SendSuccess = 'SendSuccess',
}

enum GlobalModals {
  init = '',
  ConnectWallet = 'ConnectWallet',
}

export const Modals = {
  ...GlobalModals,
  ...TransactionModals,
} as const;

type ModalsType = TransactionModals | GlobalModals;

export interface ModalState {
  activeModal: ModalsType;
  txHash: string;
  open: boolean;
  repeatCallback?: () => void;
}

export type ModalsInitialState = {
  modalState: ModalState;
};
