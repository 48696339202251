import { Divider, ModalProps, Stack, styled, Typography } from '@mui/material';
import { Button, Icon, ModalWrapper } from 'components';
import { Chains, ConnectProps, useWalletConnectorContext, WalletProviders } from 'services';
import { COLOR_BG } from 'styles';

const ConnectToButton = styled(Button)<{
  orientation?: 'top' | 'bottom';
}>(({ orientation }) => ({
  borderRadius: (orientation && (orientation === 'top' ? '20px 20px 0 0' : '0 0 20px 20px')) || '0px',
  justifyContent: 'start',
  height: 60,
  background: COLOR_BG,
  fontSize: 16,
}));

export interface ConnectWalletModalProps extends Partial<ModalProps> {
  open: boolean;
  onClose: () => void;
}

export const ConnectWalletModal = ({ open, onClose }: ConnectWalletModalProps) => {
  const { connect } = useWalletConnectorContext();

  const handleConnectWallet = async ({ provider, chain }: ConnectProps) => {
    await connect({ provider, chain });
    onClose();
  };

  return (
    <ModalWrapper
      onClose={onClose}
      open={open}
      title={<Typography className="secondary semi-bold">Connect Wallet</Typography>}
      sx={{ width: 'auto', padding: 2 }}
    >
      <Stack direction="column" width={306} mt={2} divider={<Divider />}>
        <ConnectToButton
          onClick={() => handleConnectWallet({ provider: WalletProviders.metamask, chain: Chains.Ethereum })}
          color="secondary"
          orientation="top"
          startIcon={<Icon.Metamask />}
        >
          MetaMask
        </ConnectToButton>
        <ConnectToButton
          onClick={() => handleConnectWallet({ provider: WalletProviders.walletConnect, chain: Chains.Ethereum })}
          color="secondary"
          orientation="bottom"
          startIcon={<Icon.WalletConnect />}
        >
          Wallet Connect
        </ConnectToButton>
      </Stack>
    </ModalWrapper>
  );
};
