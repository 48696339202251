import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronRight: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 19"
    sx={{
      width: '18px',
      height: '19px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M6.75 4.59033L11.25 9.09033L6.75 13.5903"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
