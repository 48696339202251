import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowSelector } from 'lib';
import { closeModal, getModals } from 'store/modals';
import { Modals, TransactionModals } from 'types';

import { ConnectWalletModal, TransactionModal } from './components';

const txModals = new Set([
  Modals.ApprovePending,
  Modals.ApproveRejected,
  Modals.SendPending,
  Modals.SendRejected,
  Modals.SendSuccess,
]);

export const AppModal: FC = () => {
  const dispatch = useDispatch();
  const { activeModal, open } = useShallowSelector(getModals);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  if (activeModal === Modals.init) return null;

  return (
    <>
      {activeModal === Modals.ConnectWallet && <ConnectWalletModal open={open} onClose={handleCloseModal} />}
      {txModals.has(activeModal as TransactionModals) && <TransactionModal open={open} onClose={handleCloseModal} />}
    </>
  );
};
