import Web3 from 'web3';

import type { ChainType, IConnectWallet, TChain, TChains } from '../types';
import { Chains, chainTypesMap, WalletProviders } from '../types';

import {
  mapChainToBlockExplorerUrl,
  mapChainToChainId,
  mapChainToChainName,
  mapChainToNativeCurrency,
  mapChainToRpc,
} from './chains.helper';

type ChainsConfig = {
  chain: Chains;
  providers: WalletProviders[];
}[];

const getChainsConfig = (chainsConfig: ChainsConfig): TChains => {
  const mapProvider = (chain: Chains, chainType: ChainType, providerName: WalletProviders) => {
    if (providerName === WalletProviders.metamask) {
      return { name: WalletProviders.metamask };
    }
    if (providerName === WalletProviders.walletConnect) {
      const chainId = mapChainToChainId[chain][chainType];
      const rpc = mapChainToRpc[chain][chainType];

      return {
        name: WalletProviders.walletConnect,
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: { [chainId]: rpc },
            chainId,
            wcConfig: {
              chains: [chainId],
              projectId: process.env.REACT_APP_WC_ID!,
              showQrModal: true,
              methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
              qrModalOptions: {
                themeVariables: {
                  '--wcm-z-index': '9999',
                },
              },
            },
          },
        },
      };
    }

    return null;
  };

  return chainsConfig.reduce((acc, { chain, providers }) => {
    const chainData: Record<ChainType, TChain> = { mainnet: {} as TChain, testnet: {} as TChain };

    Object.values(chainTypesMap).forEach((chainType) => {
      const chainId = mapChainToChainId[chain][chainType];
      const nativeCurrency = mapChainToNativeCurrency[chain][chainType];
      const rpc = mapChainToRpc[chain][chainType];
      const blockExplorerUrl = mapChainToBlockExplorerUrl[chain][chainType];
      const chainName = mapChainToChainName[chain][chainType];

      chainData[chainType] = {
        name: chainName,
        chainId,
        nativeCurrency,
        rpc,
        blockExplorerUrl,
        provider: providers.reduce((providersAcc, providerName) => {
          const newProvider = mapProvider(chain, chainType, providerName);
          if (newProvider) {
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            providersAcc[providerName] = newProvider;
          }
          return providersAcc;
        }, {}),
        network: {
          chainId: Web3.utils.toHex(chainId),
          chainName: `${chain} ${chainType === chainTypesMap.mainnet ? 'Mainnet' : 'Testnet'}`,
          nativeCurrency,
          rpcUrls: [rpc],
        },
      };
    });

    acc[chain] = chainData;
    return acc;
  }, {} as TChains);
};

export const chains: TChains = getChainsConfig([
  {
    chain: Chains.Ethereum,
    providers: [WalletProviders.metamask, WalletProviders.walletConnect],
  },
]);

export const connectWallet = (newChainName: Chains, type: ChainType): IConnectWallet => {
  const chain = chains?.[newChainName]?.[type];
  return {
    network: {
      chainName: chain?.name,
      chainID: chain?.chainId,
      nativeCurrency: chain?.nativeCurrency,
      rpc: chain?.rpc,
      blockExplorerUrl: chain?.blockExplorerUrl,
    },
    provider: chain?.provider,
    settings: { providerType: true },
  };
};
