import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { GeneralSansMedium, GeneralSansRegular, Montserrat, MontserratBold, ReadexProRegular } from 'assets/fonts';
import { buildFontFaceRule } from 'lib';

import {
  COLOR_BG,
  COLOR_BLUE_DUSK,
  COLOR_BLUE_ETHEREAL,
  COLOR_BLUE_MISTY,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_YELLOW_LIGHT,
  FontFamilies,
} from '../variables';

const fontConfigs = [
  {
    fontFamily: 'General Sans',
    path: GeneralSansRegular,
    fontStyle: 'normal',
    fontWeight: 400,
    format: 'ttf',
  },
  {
    fontFamily: 'General Sans',
    path: GeneralSansMedium,
    fontStyle: 'medium',
    fontWeight: 500,
    format: 'ttf',
  },
  {
    fontFamily: 'Montserrat',
    path: Montserrat,
    fontStyle: 'semi-bold',
    fontWeight: 600,
    format: 'ttf',
  },
  {
    fontFamily: 'Montserrat',
    path: MontserratBold,
    fontStyle: 'extra-bold',
    fontWeight: 800,
    format: 'ttf',
  },
  {
    fontFamily: 'Readex Pro',
    path: ReadexProRegular,
    fontStyle: 'normal',
    fontWeight: 400,
    format: 'ttf',
  },
] as const;

export const getMuiCssBaselineOverrides = (theme: Theme): ComponentsOverrides['MuiCssBaseline'] => ({
  '*::-webkit-scrollbar': {
    width: 6,
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: COLOR_BLUE_MISTY,
    borderRadius: 20,
  },
  html: {
    WebkitFontSmoothing: 'antialiased', // Antialiasing.
    MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    boxSizing: 'border-box',

    ...fontConfigs.map(buildFontFaceRule),
  },

  body: {
    overflowX: 'hidden',
    overflowY: 'overlay',
    margin: 0,
    fontFamily: FontFamilies.primary,
    fontSize: 14,
    background: COLOR_BG,

    'span, div': {
      fontFeatureSettings: 'liga off',
    },
  },

  '.Toastify__toast': {
    minHeight: '56px !important',
    border: '1px solid !important',
    borderRadius: '12px !important',
    fontWeight: '400 !important',
    wordBreak: 'break-word',
    marginBottom: 0,
    backgroundColor: COLOR_BLUE_DUSK,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1.25),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
    },

    svg: {
      width: '22px !important',
    },

    '&-body': {
      padding: '0 !important',
    },
    '&-icon': {
      width: 'fit-content !important',
    },

    '&--success': {
      borderColor: `${COLOR_GREEN} !important`,
      color: `${COLOR_GREEN} !important`,

      '.Toastify__toast-icon svg': {
        fill: COLOR_GREEN,
      },
      '.Toastify__close-button': {
        color: `${COLOR_GREEN} !important`,
      },
    },
    '&--info': {
      borderColor: `${COLOR_BLUE_ETHEREAL} !important`,
      color: `${COLOR_BLUE_ETHEREAL} !important`,

      '.Toastify__toast-icon svg': {
        fill: COLOR_BLUE_ETHEREAL,
      },
      '.Toastify__close-button': {
        color: `${COLOR_BLUE_ETHEREAL} !important`,
      },
    },
    '&--warning': {
      borderColor: `${COLOR_YELLOW_LIGHT} !important`,
      color: `${COLOR_YELLOW_LIGHT} !important`,

      '.Toastify__toast-icon svg': {
        fill: COLOR_YELLOW_LIGHT,
      },
      '.Toastify__close-button': {
        color: `${COLOR_YELLOW_LIGHT} !important`,
      },
    },
    '&--error': {
      borderColor: `${COLOR_RED} !important`,
      color: `${COLOR_RED} !important`,

      '.Toastify__toast-icon svg': {
        fill: COLOR_RED,
      },
      '.Toastify__close-button': {
        color: `${COLOR_RED} !important`,
      },
    },
    '&--default': {
      borderColor: 'rgba(94, 153, 245, 1) !important',
      color: 'rgba(94, 153, 245, 1) !important',

      '.Toastify__close-button': {
        color: 'rgba(94, 153, 245, 1) !important',
      },
    },
  },

  '.Toastify__close-button': {
    alignSelf: 'center !important',
    width: '25px !important',
    height: '25px !important',
    opacity: 1,
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
