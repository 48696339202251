import { Stack, styled } from '@mui/material';
import { BORDER_RADIUS_L, COLOR_BLUE_DUSK } from 'styles';

export const Wrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '627px',
  width: '100%',
  borderRadius: BORDER_RADIUS_L,
  padding: theme.spacing(4, 7.5),
  background: COLOR_BLUE_DUSK,
  outline: 'none',
}));
