import { type ChainMap, type ChainType, type NativeCurrency, Chains } from '../types';

export const mapChainToChainId: ChainMap<number> = {
  [Chains.Ethereum]: {
    mainnet: 1,
    testnet: 11155111,
  },
};

export const mapChainToChainName: ChainMap<string> = {
  [Chains.Ethereum]: {
    mainnet: 'Ethereum',
    testnet: 'Sepolia',
  },
};

export const mapChainToNativeCurrency: ChainMap<NativeCurrency> = {
  [Chains.Ethereum]: {
    mainnet: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    testnet: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};

export const getChainDecimals = (chain: Chains, network: ChainType) =>
  mapChainToNativeCurrency[chain][network].decimals;

export const mapChainToRpc: ChainMap<string> = {
  [Chains.Ethereum]: {
    mainnet: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID_PROJECT}`,
    testnet: `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID_PROJECT}`,
    // mainnet: 'https://rpc.ankr.com/eth', // `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
    // testnet: 'https://ethereum-goerli.publicnode.com', // `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  },
};

export const mapChainToBlockExplorerUrl: ChainMap<string> = {
  [Chains.Ethereum]: {
    mainnet: 'https://etherscan.io',
    testnet: 'https://sepolia.etherscan.io',
  },
};
