import { Typography } from '@mui/material';
import { PaperBox } from 'styles';

export const UserNotParticipant = () => {
  return (
    <PaperBox paddingPx={12} direction="row" alignItems="center" justifyContent="center" sx={{ aspectRatio: '2.75' }}>
      <Typography className="medium" textAlign="center">
        You are not eligible for <br /> K9 vesting or your vesting has ended
      </Typography>
    </PaperBox>
  );
};
