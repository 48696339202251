import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Info: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 18"
    sx={{
      width: '18px',
      height: '18px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M8.99925 6C8.89575 6 8.81175 6.084 8.8125 6.1875C8.8125 6.291 8.8965 6.375 9 6.375C9.1035 6.375 9.1875 6.291 9.1875 6.1875C9.1875 6.084 9.1035 6 8.99925 6"
      stroke="#E1E3ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 15.75V15.75C5.27175 15.75 2.25 12.7283 2.25 9V9C2.25 5.27175 5.27175 2.25 9 2.25V2.25C12.7283 2.25 15.75 5.27175 15.75 9V9C15.75 12.7283 12.7283 15.75 9 15.75Z"
      stroke="#E1E3ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 9V12.75" stroke="#E1E3ED" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
