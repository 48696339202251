import { ComponentProps, FC, forwardRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Button, Icon } from 'components';
import { COLOR_WH } from 'styles';

export const ConnectWalletButton: FC<ComponentProps<typeof Button>> = forwardRef((props, ref) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      {...props}
      ref={ref}
      {...(isSmScreen && {
        minWidth: 'auto',
        width: 'fit-content',
        sx: { borderRadius: '50%', padding: theme.spacing(1, 1.25) },
      })}
    >
      {isSmScreen ? <Icon.Wallet sx={{ color: COLOR_WH }} /> : 'Connect wallet'}
    </Button>
  );
});

ConnectWalletButton.displayName = 'ConnectWalletButton';
