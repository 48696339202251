import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_RADIUS_S, COLOR_BLUE_DUSK } from '../variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiPopoverOverrides = (theme: Theme): ComponentsOverrides['MuiPopover'] => ({
  root: {
    '.MuiBackdrop-root': {
      background: 'rgba(0,0,0,0)',
    },
    '.MuiPopover-paper': {
      padding: theme.spacing(1.5),
      background: COLOR_BLUE_DUSK,
      borderRadius: BORDER_RADIUS_S,
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
    },
  },
});

export const getMuiPopoverDefaultProps = (): ComponentsProps['MuiPopover'] => ({});
