import { useCallback } from 'react';
import { useAppDispatch, useShallowSelector } from 'lib';
import { useWeb3Provider } from 'services';
import { getUserState } from 'store/user';
import { claimTokens, getVestingInfo, getVestingState } from 'store/vesting';

import {
  ClaimAmounts,
  ClaimBlock,
  ClaimDates,
  ConnectUserBlock,
  TokenInfo,
  UserNotParticipant,
} from '../../components';

import { FormBox, useVestingState } from './vesting-widget.helper';
import { ClaimDatesCliff } from 'pages/landing/components/claim-dates-cliff';

export const VestingWidget = () => {
  const dispatch = useAppDispatch();
  const web3Provider = useWeb3Provider();

  const { userBalance, address } = useShallowSelector(getUserState);
  const {
    totalVested,
    toBeClaimed,
    totalClaimed,
    availableToClaim,
    nextClaim,
    start,
    end,
    isUserInVesting,
    vestingPeriod,
  } = useShallowSelector(getVestingState);

  const handleClaimTokens = useCallback(() => {
    dispatch(claimTokens({ web3Provider }));
  }, [dispatch, web3Provider]);

  const updateVestingInfo = useCallback(() => {
    dispatch(getVestingInfo({ web3Provider }));
  }, [dispatch, web3Provider]);

  const { isVestingStarted, isVestingOver, timerEndTimeStamp, timerStartTimeStamp } = useVestingState({
    startTimestampSec: +start,
    endTimestampSec: +end,
    onUpdateData: updateVestingInfo,
    nextClaimTimestampSec: +nextClaim,
    vestingPeriodSec: +vestingPeriod,
  });

  return (
    <FormBox mx="auto" spacing={0.75}>
      <TokenInfo totalVested={totalVested} userTokenBalance={userBalance} />

      {!address && <ConnectUserBlock />}

      {!isUserInVesting && address && <UserNotParticipant />}

      {isVestingStarted && address && isUserInVesting && (
        <>
          <ClaimDates
            isEnded={isVestingOver}
            claimEndTimestamp={end}
            nextClaimTimestamp={nextClaim}
            availableToClaim={availableToClaim}
          />
          <ClaimBlock
            // title={isVestingStarted ? 'Next claim will be available in' : 'Vesting start date xx'}
            title="Next claim will be available in"
            availableToClaim={availableToClaim}
            onClaim={handleClaimTokens}
            isEnded={isVestingOver}
            endTimestampSec={timerEndTimeStamp}
            startTimestampSec={timerStartTimeStamp ? +timerStartTimeStamp : Date.now()}
          />
          <ClaimAmounts toBeClaimed={toBeClaimed} totalClaimed={totalClaimed} />
        </>
      )}

      {!isVestingStarted && address && isUserInVesting && (
        <>
          <ClaimDatesCliff
            cliffEndTimestamp={start}
            isEnded={isVestingOver}
            claimEndTimestamp={end}
            nextClaimTimestamp={nextClaim}
            availableToClaim={availableToClaim}
          />
          <ClaimBlock
            // title={isVestingStarted ? 'Next claim will be available in' : 'Vesting start date xx'}
            title="Next claim will be available in"
            availableToClaim={availableToClaim}
            onClaim={handleClaimTokens}
            isEnded={isVestingOver}
            endTimestampSec={timerEndTimeStamp}
            startTimestampSec={timerStartTimeStamp ? +timerStartTimeStamp : Date.now()}
          />
          <ClaimAmounts toBeClaimed={toBeClaimed} totalClaimed={totalClaimed} />
        </>
      )}
    </FormBox>
  );
};
