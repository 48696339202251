import { FC, useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { BaseModalProps, Copyable, ModalWrapper } from 'components';
import { getExplorerUrl, useAppDispatch, useShallowSelector } from 'lib';
import { closeModal, getModals } from 'store/modals';
import { getUserState } from 'store/user';
import { COLOR_GRAY_DARK, FontFamilies, FontWeights } from 'styles';
import { TransactionModals } from 'types';

import { transactionModalData } from './transaction-modal.helper';

export const TransactionModal: FC<BaseModalProps> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { chainType, network } = useShallowSelector(getUserState);
  const { activeModal, txHash, repeatCallback } = useShallowSelector(getModals);

  // disable-reason: due to Partial<Modals> TS expects that there possible undefined, but IT CANNOT BE
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { title1, title2, icon, body1, body2, isCopyable, buttonText } =
    transactionModalData[activeModal as TransactionModals]!;

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const scannerLink = getExplorerUrl(txHash, network, chainType);

  return (
    <ModalWrapper title={title1} title2={title2} onClose={handleCloseModal} {...props}>
      <Stack mt={4.25} alignItems="center">
        {icon}

        {body1 && (
          <Typography
            variant="h3"
            fontFamily={FontFamilies.tertiary}
            fontWeight={FontWeights.SemiBold}
            textAlign="center"
            mt={3}
            maxWidth={335}
          >
            {body1}
          </Typography>
        )}

        {body2 && (
          <Typography
            mt={2}
            variant="body2"
            fontFamily={FontFamilies.tertiary}
            textAlign="center"
            color={COLOR_GRAY_DARK}
          >
            {body2}
          </Typography>
        )}

        {isCopyable && scannerLink && <Copyable copyText={scannerLink} displayText={scannerLink} mt={3} isLink />}
        {buttonText && (
          <Button size="medium" onClick={repeatCallback} sx={{ minWidth: 204, mt: 3 }}>
            {buttonText}
          </Button>
        )}
      </Stack>
    </ModalWrapper>
  );
};
