import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routesConfig } from 'app-constants';
import { Landing } from 'pages/landing';

export const RouteManager: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path={routesConfig.landing.root} element={<Landing />} />
    </Routes>
  );
};
