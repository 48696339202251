import { Box, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { dog, shibariumLogo } from 'assets';

import { socials } from './footer.helper';

export const Footer = () => {
  return (
    <Stack width="100%" mt="auto" pb={2.5} spacing={2.5}>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xxs={12} xs={12} md={3} order={{ xxs: 2, md: 1 }}>
          <Typography
            fontSize="13px"
            textTransform="uppercase"
            className="neutral medium"
            letterSpacing="0.13px"
            textAlign={{ xxs: 'center', md: 'left' }}
          >
            © 2024 K9 Finance DAO. All Rights Reserved.
          </Typography>
        </Grid>

        <Grid
          item
          xxs={12}
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          order={{ xs: 1, md: 2 }}
        >
          {/* <Box component="img" src={dog} width={36} height={36} alt="logo" /> */}
          <Box component="img" src={shibariumLogo} width={52} height={36} alt="logo" />
        </Grid>

        <Grid item xxs={12} xs={12} md={3} order={3}>
          <Stack direction="row" alignItems="center" justifyContent={{ xxs: 'center', md: 'flex-end' }} spacing={2.25}>
            {socials.map(({ href, Icon }) => (
              <IconButton
                component={Link}
                href={href}
                target="_blank"
                rel="noreferrer"
                className="contained rounded"
                key={href}
              >
                <Icon />
              </IconButton>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
