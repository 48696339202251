import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_RADIUS_DEFAULT, COLOR_BLACK, COLOR_WH, SHADOW_GRAY } from '../variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTooltipOverrides = (theme: Theme): ComponentsOverrides['MuiTooltip'] => ({
  tooltip: {
    borderRadius: BORDER_RADIUS_DEFAULT,
    background: COLOR_BLACK,
    fontSize: '13px',
    lineHeight: '18px',
    padding: theme.spacing(1.5),
    color: COLOR_WH,
    boxShadow: SHADOW_GRAY,
    maxWidth: '350px',

    '&.small': {
      maxWidth: '205px',
    },

    '.MuiTooltip-arrow': {
      color: COLOR_BLACK,
      '&:before': {
        border: `1px solid ${COLOR_BLACK}`,
      },
    },
  },
});

export const getMuiTooltipDefaultProps = (): ComponentsProps['MuiTooltip'] => ({
  arrow: true,
  placement: 'top',
  leaveDelay: 250,
  followCursor: false,
});
