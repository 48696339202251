import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MetamaskWhite: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 42 42"
    sx={{
      width: '42px',
      height: '42px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M34.4911 8.0061L23.5503 16.13L25.5825 11.337L34.4911 8.0061Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M9.25879 8.0061L20.1007 16.207L18.1674 11.3371L9.25879 8.0061Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M30.558 26.8373L27.647 31.3005L33.8753 33.0154L35.6549 26.9362L30.558 26.8373Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M8.10547 26.9362L9.87401 33.0154L16.1023 31.3005L13.2024 26.8373L8.10547 26.9362Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M15.762 19.296L14.0264 21.9234L20.1998 22.1982L19.9911 15.5474L15.762 19.296Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M27.9876 19.2959L23.6926 15.4703L23.5498 22.1981L29.7232 21.9233L27.9876 19.2959Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M16.1025 31.3005L19.8263 29.4866L16.6188 26.9802L16.1025 31.3005Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M23.9233 29.4866L27.6472 31.3005L27.1309 26.9802L23.9233 29.4866Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M27.6476 31.3005L23.9238 29.4866L24.2204 31.9161L24.1874 32.9385L27.6476 31.3005Z"
      fill="#D0D0D0"
      stroke="#D0D0D0"
      strokeWidth="0.0833333"
    />
    <path
      d="M16.1025 31.301L19.5627 32.9389L19.5408 31.9166L19.8264 29.4871L16.1025 31.301Z"
      fill="#D0D0D0"
      stroke="#D0D0D0"
      strokeWidth="0.0833333"
    />
    <path
      d="M19.629 25.3753L16.5312 24.4628L18.7172 23.4625L19.629 25.3753Z"
      fill="#4A4A4A"
      stroke="#4A4A4A"
      strokeWidth="0.0833333"
    />
    <path
      d="M24.1216 25.3753L25.0334 23.4625L27.2303 24.4628L24.1216 25.3753Z"
      fill="#4A4A4A"
      stroke="#4A4A4A"
      strokeWidth="0.0833333"
    />
    <path
      d="M16.1021 31.3005L16.6404 26.8373L13.2021 26.9362L16.1021 31.3005Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M27.1094 26.8373L27.6476 31.3005L30.5586 26.9362L27.1094 26.8373Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M29.7238 21.9233L23.5503 22.1981L24.1216 25.3751L25.0333 23.4623L27.2302 24.4627L29.7238 21.9233Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M16.5309 24.4627L18.7169 23.4623L19.6286 25.3751L20.1998 22.1981L14.0264 21.9233L16.5309 24.4627Z"
      fill="#DEDEDE"
      stroke="#DEDEDE"
      strokeWidth="0.0833333"
    />
    <path
      d="M14.0264 21.9236L16.6188 26.9804L16.5309 24.463L14.0264 21.9236Z"
      fill="#E9E9E9"
      stroke="#E9E9E9"
      strokeWidth="0.0833333"
    />
    <path
      d="M27.2297 24.4628L27.1309 26.9802L29.7233 21.9233L27.2297 24.4628Z"
      fill="#E9E9E9"
      stroke="#E9E9E9"
      strokeWidth="0.0833333"
    />
    <path
      d="M20.1996 22.1979L19.6284 25.3749L20.3534 29.1235L20.5072 24.1876L20.1996 22.1979Z"
      fill="#E9E9E9"
      stroke="#E9E9E9"
      strokeWidth="0.0833333"
    />
    <path
      d="M23.5495 22.1978L23.2529 24.1765L23.3957 29.1234L24.1207 25.3748L23.5495 22.1978Z"
      fill="#E9E9E9"
      stroke="#E9E9E9"
      strokeWidth="0.0833333"
    />
    <path
      d="M24.1215 25.3753L23.3965 29.124L23.9237 29.4867L27.1313 26.9803L27.2301 24.4629L24.1215 25.3753Z"
      fill="#F9F9F9"
      stroke="#F9F9F9"
      strokeWidth="0.0833333"
    />
    <path
      d="M16.5312 24.4629L16.6192 26.9803L19.8267 29.4867L20.354 29.124L19.629 25.3753L16.5312 24.4629Z"
      fill="#F9F9F9"
      stroke="#F9F9F9"
      strokeWidth="0.0833333"
    />
    <path
      d="M24.1873 32.9385L24.2202 31.9162L23.9456 31.6743H19.8044L19.5408 31.9162L19.5627 32.9385L16.1025 31.3005L17.3109 32.2899L19.7714 33.9939H23.9786L26.4391 32.2899L27.6475 31.3005L24.1873 32.9385Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      strokeWidth="0.0833333"
    />
    <path
      d="M23.9239 29.4867L23.3966 29.124H20.3538L19.8266 29.4867L19.541 31.9162L19.8046 31.6744H23.9458L24.2205 31.9162L23.9239 29.4867Z"
      fill="#141414"
      stroke="#141414"
      strokeWidth="0.0833333"
    />
    <path
      d="M34.9524 16.6577L35.8751 12.1725L34.4911 8.0061L23.9238 15.8552L27.9881 19.2961L33.7331 20.978L34.9964 19.494L34.4471 19.0982L35.3259 18.2957L34.6558 17.768L35.5346 17.0975L34.9524 16.6577Z"
      fill="#757575"
      stroke="#757575"
      strokeWidth="0.0833333"
    />
    <path
      d="M7.875 12.1726L8.8087 16.6578L8.21553 17.0975L9.0943 17.7681L8.42424 18.2958L9.30301 19.0983L8.75378 19.494L10.017 20.9781L15.762 19.2962L19.8264 15.8553L9.25907 8.00623L7.875 12.1726Z"
      fill="#757575"
      stroke="#757575"
      strokeWidth="0.0833333"
    />
    <path
      d="M33.7327 20.9778L27.9877 19.2959L29.7232 21.9233L27.1309 26.9801L30.5581 26.9361H35.655L33.7327 20.9778Z"
      fill="#F9F9F9"
      stroke="#F9F9F9"
      strokeWidth="0.0833333"
    />
    <path
      d="M15.7618 19.2959L10.0168 20.9778L8.10547 26.9361H13.2024L16.6186 26.9801L14.0262 21.9233L15.7618 19.2959Z"
      fill="#F9F9F9"
      stroke="#F9F9F9"
      strokeWidth="0.0833333"
    />
    <path
      d="M23.55 22.1982L23.9235 15.8552L25.5822 11.337H18.1675L19.8262 15.8552L20.1997 22.1982L20.3425 24.199L20.3534 29.1239H23.3962L23.4072 24.199L23.55 22.1982Z"
      fill="#F9F9F9"
      stroke="#F9F9F9"
      strokeWidth="0.0833333"
    />
    <path
      d="M19.5943 15C19.5943 19.1942 16.1942 22.5943 12 22.5943C7.8058 22.5943 4.40574 19.1942 4.40574 15C4.40574 10.8058 7.8058 7.40574 12 7.40574C16.1942 7.40574 19.5943 10.8058 19.5943 15Z"
      fill="#757575"
      stroke="white"
      strokeWidth="0.811476"
    />
    <path
      d="M10.9523 21.4572V9.15063H13.0407V21.4572H10.9523ZM5.84326 16.3481V14.2597H18.1498V16.3481H5.84326Z"
      fill="white"
    />
  </SvgIcon>
);
