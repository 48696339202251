import type { INetwork, IProvider, ISettings } from '@amfi/connect-wallet/src/interface';
/**
 * Should strict equal to @see https://github.com/Rock-n-Block/connect-wallet-lib/blob/6ae2e4eb5292a9685da8902505faa8623a385b8a/src/index.ts#L123
 */
export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
}

export interface IConnectWallet {
  network: INetwork;
  provider: {
    [index: string]: IProvider;
  };
  settings: ISettings;
}

export type Token = {
  address: string;
  symbol: string;
  decimals: number;
  image?: string;
};

export * from './contracts';
export * from './chains';
