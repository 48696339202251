import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Warn: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 100 100"
    sx={{
      width: '100px',
      height: '100px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM42.7425 22.1588H57.2556L55.9283 63.1589H44.0698L42.7425 22.1588ZM57.5153 74.5847C57.4576 78.7395 53.9663 82.0865 50.0135 82.0865C45.8587 82.0865 42.454 78.7395 42.5117 74.5847C42.454 70.4876 45.8587 67.1695 50.0135 67.1695C53.9663 67.1695 57.4576 70.4876 57.5153 74.5847Z"
      fill="url(#paint0_linear_8_2371)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8_2371"
        x1="87.4206"
        y1="5.73618"
        x2="-3.12915"
        y2="13.6253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE6E1A" />
        <stop offset="1" stopColor="#FC973B" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
