import { MouseEventHandler, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconButton, Link, Stack, Typography } from '@mui/material';
import { Icon } from 'components';
import { BORDER_RADIUS_S, COLOR_ACCENT, COLOR_BLUE_DARK_NAVY } from 'styles';

import { COPY_TEXT_SHOW_TIME, copyText as copyTextHelpers } from './copyable.helper';
import { CopyableProps } from './copyable.types';

export const Copyable = ({ copyText, displayText, isLink, ...stackProps }: CopyableProps) => {
  const [helperText, setHelperText] = useState(displayText);
  const handleCopyText: MouseEventHandler<HTMLButtonElement> = (event) => {
    setHelperText(copyTextHelpers.success);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText(displayText);
    }, COPY_TEXT_SHOW_TIME);
    return () => clearTimeout(timer);
  }, [displayText, helperText]);

  return (
    <CopyToClipboard text={copyText}>
      <Stack
        direction="row"
        spacing={0.75}
        alignItems="center"
        justifyContent="space-between"
        {...stackProps}
        sx={{
          width: '100%',
          background: COLOR_BLUE_DARK_NAVY,
          paddingX: 2.5,
          paddingY: 2,
          borderRadius: BORDER_RADIUS_S,
          ...stackProps.sx,
        }}
      >
        {isLink && (
          <>
            <Link href={helperText} className="s" maxWidth="100%" fontWeight={500} noWrap target="_blank">
              <Typography className="s" maxWidth="100%" fontWeight={500} noWrap>
                {helperText}
              </Typography>
            </Link>
            <IconButton aria-label="Open in new tab" component="a" href={helperText} target="_blank">
              <Icon.OpenInNew sx={{ color: COLOR_ACCENT }} />
            </IconButton>
          </>
        )}

        {!isLink && (
          <>
            <Typography noWrap className="s" maxWidth="100%" fontWeight={500}>
              {helperText}
            </Typography>
            <IconButton onClick={handleCopyText}>
              <Icon.Copy sx={{ color: COLOR_ACCENT }} />
            </IconButton>
          </>
        )}
      </Stack>
    </CopyToClipboard>
  );
};
