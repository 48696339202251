import { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icon, TokenAmount } from 'components';
import { formatWithDots } from 'lib';
import { COLOR_ORANGE_BRIGHT, PaperBox } from 'styles';

interface TokenInfoProps {
  totalVested: string;
  userTokenBalance: string;
}

const getConnectedValueOrDefault = (value: Nullable<string>): string => (value === null ? '-' : formatWithDots(value));

export const TokenInfo = memo(({ totalVested, userTokenBalance }: TokenInfoProps) => {
  return (
    <Stack direction={{ xs: 'row' }} width="100%" spacing={{ xxs: 0.75 }}>
      <PaperBox>
        <Typography variant="caption" className="medium neutral">
          Starting total
        </Typography>
        <TokenAmount amount={getConnectedValueOrDefault(totalVested)} mt={1} />
      </PaperBox>

      <PaperBox>
        <Typography variant="caption" className="medium neutral">
          My wallet balance
        </Typography>

        <Stack direction="row" spacing={1} alignItems="center" mt={1}>
          <Icon.Wallet sx={{ color: COLOR_ORANGE_BRIGHT }} />
          <Typography variant="h3">{getConnectedValueOrDefault(userTokenBalance)}</Typography>
        </Stack>
      </PaperBox>
    </Stack>
  );
});

TokenInfo.displayName = 'TokenInfo';
