import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_BLUE_DUSK, COLOR_WH, TRANSITION_BEZIER_DEFAULT } from '../variables';

export const getMuiIconButtonOverrides = (theme: Theme): ComponentsOverrides['MuiIconButton'] => ({
  root: {
    padding: 0,
    color: COLOR_WH,
    '&:hover': {
      background: 'transparent',
    },

    '&.contained': {
      background: COLOR_BLUE_DUSK,
      padding: theme.spacing(1),

      '&:active': {
        transform: 'scale(0.9)',
        transition: 'all 0.05s ease-in-out',
      },
    },

    '&.rounded': {
      borderRadius: '50%',
    },

    svg: {
      transition: TRANSITION_BEZIER_DEFAULT,
    },
  },
});

export const getMuiIconButtonDefaultProps = (): ComponentsProps['MuiIconButton'] => ({
  disableFocusRipple: true,
  disableRipple: true,
});
