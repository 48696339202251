import BigNumber from 'bignumber.js';
import { createAppAsyncThunk, fromDecimals, logger } from 'lib';
import { ContractsNames, ContractsService, getTokenInfo } from 'services';
import type { RequestWithWeb3Provider } from 'types';
import { VestingState } from 'types';

import actionTypes from '../action-types';

const appendSeconds = (value: string, increaseSecs = 30) => {
  if (+value === 0) {
    return value;
  }

  return new BigNumber(value).plus(increaseSecs).toString();
};

export const getVestingInfo = createAppAsyncThunk(
  actionTypes.GET_VESTING_INFO,
  async (
    { web3Provider }: RequestWithWeb3Provider,
    { rejectWithValue, getState },
  ): Promise<Partial<VestingState> | ReturnType<typeof rejectWithValue>> => {
    try {
      const { network, chainType, address: userAddress } = getState().user;

      const vestingContract = new ContractsService(web3Provider).getVestingContract(network, chainType);

      const { totalVested, toBeClaimed, availableToClaim, alreadyClaimed, nextClaim, start, end } =
        await vestingContract.methods.getUserInfo(userAddress).call();

      const vestingPeriodInSec = await vestingContract.methods.MONTH().call();

      const { decimals } = getTokenInfo(network, ContractsNames.K9Token, chainType);

      return {
        isUserInVesting: +start !== 0 && +end !== 0 && +totalVested !== 0 && +nextClaim !== 0,
        totalClaimed: fromDecimals(alreadyClaimed, decimals),
        toBeClaimed: fromDecimals(toBeClaimed, decimals),
        totalVested: fromDecimals(totalVested, decimals),
        availableToClaim: fromDecimals(availableToClaim, decimals),
        vestingPeriod: vestingPeriodInSec,
        nextClaim: appendSeconds(nextClaim),
        start: appendSeconds(start),
        end: appendSeconds(end),
      };
    } catch (error: unknown) {
      logger('getVestingInfo', error);
      return rejectWithValue(error);
    }
  },
);
