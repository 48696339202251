export enum Chains {
  Ethereum = 'Ethereum',
}

export const chainTypesMap = {
  testnet: 'testnet',
  mainnet: 'mainnet',
} as const;

export type ChainType = keyof typeof chainTypesMap;

export type NativeCurrency = {
  name: string;
  symbol: string; // 2-6 characters long
  decimals: number;
};

export type TChain = {
  name: string;
  chainId: number;
  nativeCurrency: NativeCurrency;
  rpc: string;
  blockExplorerUrl: string;
  provider: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  img?: string;
  network?: AddEthereumChainParameter;
};

export type TChains = Record<
  Chains,
  {
    [chainType in ChainType]: TChain;
  }
>;

// @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: NativeCurrency;
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export type ChainMap<T> = Record<
  Chains,
  {
    [chainType in ChainType]: T;
  }
>;
