import { memo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Button } from 'components';
import { fromDecimals } from 'lib';

export interface TokenInfo {
  symbol: string;
  decimals: number;
  maxFractions?: number;
}

interface UserBalanceProps extends TokenInfo {
  onMaxClick: () => void;
  balanceRawValue: string;
}

export const UserBalance = memo(
  ({ onMaxClick, balanceRawValue, symbol, decimals, maxFractions = 6 }: UserBalanceProps) => {
    const balanceValueFromDecimals = fromDecimals(balanceRawValue, decimals, decimals);
    const shortValue = new BigNumber(balanceValueFromDecimals).decimalPlaces(maxFractions).toString(10);

    return (
      <Stack direction="row" spacing={1}>
        <Button color="secondary" onClick={onMaxClick} width={35} height={18} sx={{ padding: 0.25 }}>
          <Typography variant="caption" fontSize="11px" className="medium">
            Max
          </Typography>
        </Button>
        <Tooltip title={`Balance: ${balanceValueFromDecimals} ${symbol}`} placement="bottom-end">
          <Typography variant="caption" className="neutral">
            Balance: {shortValue || '0.00'} {symbol}
          </Typography>
        </Tooltip>
      </Stack>
    );
  },
);

UserBalance.displayName = 'UserBalance';
