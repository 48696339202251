import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { Button } from 'components';
import { openModal } from 'store/modals';
import { PaperBox } from 'styles';
import { Modals } from 'types';

export const ConnectUserBlock = () => {
  const dispatch = useDispatch();

  const openConnectWalletModal = () => {
    dispatch(
      openModal({
        activeModal: Modals.ConnectWallet,
        open: true,
      }),
    );
  };

  return (
    <PaperBox paddingPx={32} alignItems="center">
      <Typography className="medium" textAlign="center" letterSpacing="0.16px" mb={3.75}>
        Please connect your wallet to see your <br /> vesting details
        {/* Vesting balances will be updated soon. */}
      </Typography>

      <Button onClick={openConnectWalletModal} maxWidth={160} width="100%">
        Connect wallet
      </Button>
    </PaperBox>
  );
};
