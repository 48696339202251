export enum FontFamilies {
  primary = '"General Sans", sans-serif',
  secondary = '"Montserrat", sans-serif',
  tertiary = '"Readex Pro", sans-serif',
}

export enum FontWeights {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  ExtraBold = 800,
}
