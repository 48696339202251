import { createAppAsyncThunk, logger } from 'lib';
import { ContractsService } from 'services';
import { openModal } from 'store/modals';
import { getTokenBalance } from 'store/user';
import { getVestingInfo } from 'store/vesting';
import type { RequestWithWeb3Provider } from 'types';
import { Modals } from 'types';

import actionTypes from '../action-types';

export const claimTokens = createAppAsyncThunk(
  actionTypes.CLAIM_TOKENS,
  async ({ web3Provider }: RequestWithWeb3Provider, { rejectWithValue, dispatch, getState }) => {
    try {
      const { network, chainType, address: userAddress } = getState().user;

      const vestingContract = new ContractsService(web3Provider).getVestingContract(network, chainType);

      await dispatch(
        openModal({
          activeModal: Modals.SendPending,
          open: true,
        }),
      );

      const tx = await vestingContract.methods.claim().send({
        from: userAddress,
      });

      await dispatch(getVestingInfo({ web3Provider }));
      await dispatch(getTokenBalance({ web3Provider }));

      await dispatch(
        openModal({
          activeModal: Modals.SendSuccess,
          open: true,
          txHash: tx.transactionHash,
        }),
      );

      return null;
    } catch (error: unknown) {
      logger('claimTokens', error);

      await dispatch(
        openModal({
          activeModal: Modals.SendRejected,
          open: true,
          repeatCallback: () => dispatch(claimTokens({ web3Provider })),
        }),
      );
      return rejectWithValue(error);
    }
  },
);
