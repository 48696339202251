import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Exit: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
  >
    <path d="M15 12.8329H3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.94702 16.8329C5.42002 19.7939 8.46802 21.8329 12 21.8329C16.971 21.8329 21 17.8039 21 12.8329C21 7.86195 16.971 3.83295 12 3.83295C8.46802 3.83295 5.42002 5.87195 3.94702 8.83295"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.83295L15 12.8329L12 15.8329"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
