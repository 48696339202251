import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WalletConnect: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 42 42"
    sx={{
      width: '42px',
      height: '42px',
      fill: 'none',
      ...sx,
    }}
  >
    <rect width="42" height="42" rx="12" fill="#2D344B" />
    <path
      d="M12.1416 16.0169C17.034 11.3277 24.9661 11.3277 29.8584 16.0169L30.4472 16.5812C30.6918 16.8157 30.6918 17.1958 30.4472 17.4303L28.433 19.3608C28.3107 19.478 28.1124 19.478 27.9901 19.3608L27.1799 18.5842C23.7668 15.3129 18.2332 15.3129 14.8202 18.5842L13.9525 19.4159C13.8301 19.5331 13.6318 19.5331 13.5095 19.4159L11.4954 17.4854C11.2507 17.2509 11.2507 16.8708 11.4954 16.6363L12.1416 16.0169ZM34.0239 20.0094L35.8165 21.7276C36.0612 21.9621 36.0612 22.3422 35.8165 22.5767L27.7335 30.3242C27.4889 30.5586 27.0923 30.5586 26.8476 30.3242C26.8476 30.3242 26.8476 30.3242 26.8476 30.3242L21.1108 24.8255C21.0496 24.7669 20.9505 24.7669 20.8893 24.8255C20.8893 24.8255 20.8893 24.8255 20.8893 24.8255L15.1526 30.3242C14.908 30.5586 14.5114 30.5586 14.2667 30.3242C14.2667 30.3242 14.2667 30.3242 14.2667 30.3242L6.18346 22.5766C5.93885 22.3421 5.93885 21.962 6.18346 21.7275L7.97609 20.0093C8.22071 19.7749 8.61731 19.7749 8.86193 20.0093L14.5989 25.508C14.66 25.5666 14.7592 25.5666 14.8203 25.508C14.8203 25.508 14.8203 25.508 14.8203 25.508L20.557 20.0093C20.8016 19.7749 21.1982 19.7749 21.4428 20.0093C21.4428 20.0093 21.4428 20.0093 21.4428 20.0093L27.1798 25.508C27.2409 25.5666 27.3401 25.5666 27.4012 25.508L33.1381 20.0094C33.3827 19.775 33.7793 19.775 34.0239 20.0094Z"
      fill="#3B99FC"
    />
  </SvgIcon>
);
