import { memo } from 'react';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import { formatWithDots, shortenPhrase } from 'lib';

interface ClaimAmountsProps {
  totalClaimed: string;
  toBeClaimed: string;
  symbol?: string;
}

export const ClaimAmounts = memo(({ totalClaimed, toBeClaimed, symbol = 'KNINE' }: ClaimAmountsProps) => {
  return (
    <Stack direction="row" spacing={3} justifyContent="center" p={1.5} pb={0}>
      <Stack spacing={0.5} alignItems="center" flex={1}>
        <Typography variant="caption" className="neutral medium">
          total claimed
        </Typography>
        <Tooltip title={`${formatWithDots(totalClaimed)} ${symbol}`} placement="bottom">
          <Typography>
            {shortenPhrase(formatWithDots(totalClaimed), 8, 5)} {symbol}
          </Typography>
        </Tooltip>
      </Stack>

      <Divider orientation="vertical" className="secondary" />

      <Stack spacing={0.5} alignItems="center" flex={1}>
        <Typography variant="caption" className="neutral medium">
          to be vested
        </Typography>
        <Tooltip title={`${formatWithDots(toBeClaimed)} ${symbol}`} placement="bottom">
          <Typography>
            {shortenPhrase(formatWithDots(toBeClaimed), 8, 5)} {symbol}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  );
});
ClaimAmounts.displayName = 'ClaimAmounts';
