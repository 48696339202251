import { FC, PropsWithChildren, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { AppModal, Footer, Header } from 'containers';
import { flexHelper, useAppDispatch, useShallowSelector } from 'lib';
import { useWeb3Provider } from 'services';
import { getTokenBalance, selectIsAuthenticated } from 'store/user';
import { getVestingInfo } from 'store/vesting';
import { MessageBox } from 'pages/landing/components/message-box';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useShallowSelector(selectIsAuthenticated);
  const web3Provider = useWeb3Provider();

  const vestingComingSoonMessage =
    'Vested tokens and extended wallet connectivity will be available in this portal in the next 7 days.';

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTokenBalance({ web3Provider }));
      dispatch(getVestingInfo({ web3Provider }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          ...flexHelper({ flexDirection: 'column' }),
          overflow: 'hidden',
        }}
      >
        <Container
          sx={{
            height: '100%',
            ...flexHelper({ flexDirection: 'column' }),
            flex: 1,
          }}
        >
          <Header />
          {/* <MessageBox message={vestingComingSoonMessage} title="Please Note" /> */}
          {children}
          <Footer />
        </Container>
      </Box>
      <AppModal />
    </>
  );
};
