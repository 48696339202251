import { alpha } from '@mui/material';

export const COLOR_BLACK = '#000';
export const COLOR_WH = '#FFF';
export const COLOR_BG = '#13192A';
export const COLOR_LIGHT = '#F9F9F9';

export const COLOR_BLUE_DARK_NAVY = '#080A12';
export const COLOR_BLUE_MIDNIGHT = '#1B1F2D';
export const COLOR_BLUE_DUSK = '#2D344B';
export const COLOR_BLUE_DEEP_INDIGO = '#3F496A';
export const COLOR_BLUE_TWILIGHT = '#525E88';
export const COLOR_BLUE_SERENE = '#6774A4';
export const COLOR_BLUE_MISTY = '#8590B6';
export const COLOR_BLUE_ETHEREAL = '#A4ACC8';
export const COLOR_BLUE_CLOUDY_SILVER = '#C2C8DB';
export const COLOR_BLUE_PEARL_WHITE = '#E1E3ED';

export const COLOR_ORANGE_MAROON = '#130701';
export const COLOR_ORANGE_DARK = '#802F0A';
export const COLOR_ORANGE_ACCENT = '#EE6E21';
export const COLOR_ORANGE_BRIGHT = '#E24623';
export const COLOR_ORANGE_SALMON = '#EF7C31';
export const COLOR_ORANGE_PERSIMMON = '#F29055';
export const COLOR_ORANGE_PEACHY = '#F5A284';
export const COLOR_ORANGE_BEIGE = '#FADACF';
export const COLOR_ORANGE_PALE_PINK = '#FFF9F8';
export const COLOR_ACCENT = '#E2761B';

export const COLOR_CHINESE_WHITE = '#E0E0E0';
export const COLOR_REGENT_GRAY = '#919A9E';

export const COLOR_GREEN = '#45B36B';
export const COLOR_YELLOW_LIGHT = '#FFD166';
export const COLOR_GRAY = alpha('#EDF0F4', 0.6);
export const COLOR_GRAY_MID = '#9C9C9C';
export const COLOR_GRAY_DARK = '#8F8F8F';

export const COLOR_WH_02 = alpha(COLOR_WH, 0.2);

export const GRADIENT_ACCENT = '-webkit-linear-gradient(64deg, #DF391F 0%, #F4A73C 100%)';
export const GRADIENT_DUSK = 'linear-gradient(90deg, #2D344B -1.68%, rgba(45, 52, 75, 0.00) 85.31%)';

export const COLOR_RED = '#E42D04';
export const COLOR_PROGRESS_BAR_BG = alpha('#F96815', 0.2);
