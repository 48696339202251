import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Telegram: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 19"
    sx={{
      width: '20px',
      height: '20px',
      fill: 'none',
      ...sx,
    }}
  >
    <path
      d="M16.4064 3.91808L2.75826 8.9384C1.82683 9.29526 1.83222 9.7909 2.58737 10.0119L6.09139 11.0546L14.1987 6.17528C14.582 5.95279 14.9323 6.07248 14.6444 6.31626L8.07587 11.971H8.07433L8.07587 11.9717L7.83416 15.417C8.18826 15.417 8.34452 15.2621 8.54312 15.0792L10.2451 13.5005L13.7853 15.9949C14.4381 16.3378 14.9069 16.1616 15.0693 15.4185L17.3932 4.97105C17.6311 4.06127 17.0291 3.64933 16.4064 3.91808Z"
      fill="white"
    />
  </SvgIcon>
);
