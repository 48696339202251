import { shortenPhrase, showToastMessage } from 'lib';
import { mapChainToChainName } from 'services/wallet-service/chains';

import { type ChainType, Chains, WalletProviders } from '../types';

export const toastMessages = {
  wallet: {
    connect: {
      success: (userWalletAddress: string, isShortenPhrase = true) =>
        `Wallet connected: ${isShortenPhrase ? shortenPhrase(userWalletAddress, 3, 3) : userWalletAddress}`,
      info: {
        noExtension: (provider: WalletProviders) => `${provider} extension isn't installed or unlocked`,
        pleaseLogin: 'Please sign login message at MetaMask',
      },
      error: {
        wrongNetwork: (network: string, chainType: ChainType) =>
          `Wrong network! Please change to ${network.toUpperCase()} ${chainType.toUpperCase()} in your wallet app before connecting.`,
        defaultError: 'Something went wrong',
      },
    },
    disconnect: {
      info: 'Disconnect from wallet',
    },
  },
  approve: {
    success: 'Successfully approved',
    error: 'Approve error',
  },
  stake: {
    success: 'You staked successfully',
    error: 'Stake error',
  },
  unstake: {
    success: 'You unstaked successfully',
    error: 'Unstake error',
  },
  claim: {
    success: 'You claimed successfully',
    error: 'Claim error',
  },
};

export const notifyUserToSwitchNetwork = (network: Chains, chainType: ChainType) => {
  const networkName = mapChainToChainName[network][chainType];
  showToastMessage('error', toastMessages.wallet.connect.error.wrongNetwork(networkName, chainType));
};
