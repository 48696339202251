import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import { showToastMessage, useShallowSelector } from 'lib';
import { Subscription } from 'rxjs';
import {
  mapChainToChainId,
  mapChainToRpc,
  notifyUserToSwitchNetwork,
  toastMessages,
  WalletProviders,
  WalletService,
} from 'services/wallet-service';
import { disconnectWalletState, getUserState, updateUserState } from 'store/user';
import { clearVestingState } from 'store/vesting';
import Web3 from 'web3';

import type { ConnectProps, IAccountInfo, IContextValue, SubscribeSuccessProps } from './wallet-connect.types';

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC<PropsWithChildren> = ({ children }) => {
  const subscriberRef = useRef<Subscription | null>(null);

  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const { provider: storedProvider, chainType, network } = useShallowSelector(getUserState);

  const disconnect = useCallback(
    async ({ showNotification = true } = {}) => {
      try {
        dispatch(disconnectWalletState());
        dispatch(clearVestingState());
        await WalletConnect.resetConnect();
        subscriberRef.current?.unsubscribe();
        subscriberRef.current = null;
        if (showNotification) {
          showToastMessage('info', toastMessages.wallet.disconnect.info);
        }
      } catch (err: unknown) {
        console.log('WalletConnect context disconnect', err);
      }
    },
    [WalletConnect, dispatch],
  );

  const subscriberSuccess = useCallback(
    async (res: SubscribeSuccessProps) => {
      if (document.visibilityState !== 'visible') {
        await disconnect();
        return;
      }

      if (res.name === 'chainChanged' && res.network !== mapChainToChainId[network][chainType]) {
        notifyUserToSwitchNetwork(network, chainType);
        await disconnect();
        return;
      }

      if (res.name === 'accountsChanged') {
        await disconnect({ showNotification: true });
      }
    },
    [chainType, disconnect, network],
  );

  const subscriberError = useCallback(
    async (error: { code: number }) => {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error.code === 4) {
        notifyUserToSwitchNetwork(network, chainType);

        await disconnect();
      }

      if (error.code === 6) {
        await disconnect();
      }
    },
    [chainType, disconnect, network],
  );

  const connect = useCallback(
    async ({ provider, chain }: ConnectProps) => {
      try {
        const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);
        if (!connected) {
          await disconnect({ showNotification: false });
          return;
        }

        subscriberRef.current = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
        const accountInfo: IAccountInfo = await WalletConnect.getAccount();
        const accountAddress = (accountInfo as IConnect).address;
        if (accountAddress) {
          dispatch(
            updateUserState({
              provider: (accountInfo as IError).type,
              address: accountAddress,
              network: chain,
            }),
          );
        }
      } catch (error: unknown) {
        console.log('Error connect wallet', error);
      }
    },
    [WalletConnect, chainType, disconnect, dispatch, subscriberError, subscriberSuccess],
  );

  useEffect(() => {
    // connect user if he connected previously
    if (storedProvider && network) {
      connect({ provider: storedProvider as WalletProviders, chain: network });
    }
    // @disable-reason: this effect should work only once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Web3Context.Provider value={{ connect, disconnect, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

const useDefaultWeb3Provider = () => {
  const { network, chainType } = useShallowSelector(getUserState);
  const rpc = useMemo(() => mapChainToRpc[network][chainType], [chainType, network]);
  return useMemo(() => new Web3(rpc), [rpc]);
};

const useWeb3Provider = () => {
  const { walletService } = useWalletConnectorContext();
  const defaultWeb3Provider = useDefaultWeb3Provider();
  const web3Provider = walletService.Web3();
  return web3Provider || defaultWeb3Provider;
};

export { WalletConnectContext, useWalletConnectorContext, useWeb3Provider };
