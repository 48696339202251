import { erc20Abi, vestingAbi } from '../abi';
import { Chains, ContractsNames, IContracts } from '../types';

export const contracts: IContracts['contracts'] = {
  [ContractsNames.K9Token]: {
    testnet: {
      address: {
        [Chains.Ethereum]: '0xCEED28A1070345Be34F9dcE095E86Ef087df49f8',
        // [Chains.Ethereum]: '0x03F3982958b3A02d72C7a2e46475c80878cC1130',
      },
      abi: erc20Abi,
    },
    mainnet: {
      address: {
        [Chains.Ethereum]: '0x91fbB2503AC69702061f1AC6885759Fc853e6EaE',
      },
      abi: erc20Abi,
    },
  },
  [ContractsNames.Vesting]: {
    testnet: {
      address: {
        // [Chains.Ethereum]: '0x62D54479074f12f98F7853777a74b4D042d84eE3',
        // [Chains.Ethereum]: '0x1d0B8f15a37beCaAEd9192EF6C2679639410D59a',
        // [Chains.Ethereum]: '0xF9C9A58E21f19C0a806Cbe1E7ce24f7364a4fBdC',
        [Chains.Ethereum]: '0xd94D0C3990aa2CAf7479e5c1152E5b279812440f',

        // old goerli address
        // [Chains.Ethereum]: '0x7EFEb87B2d159ec306f51ad3A95dE0a276A0735c',
      },
      abi: vestingAbi,
    },
    mainnet: {
      address: {
        [Chains.Ethereum]: '0x38347536479F4F37b063b54Aa07E52E2fB5f5812',
      },
      abi: vestingAbi,
    },
  },
};
