import { Typography } from '@mui/material';
import { Button, Countdown, TokenAmount } from 'components';
import { formatWithDots } from 'lib';
import { PaperBox } from 'styles';

interface ClaimBlockProps {
  availableToClaim: string;
  onClaim: () => void;
  title: string;
  endTimestampSec: number;
  startTimestampSec: number;
  isEnded: boolean;
}

export const ClaimBlock = ({
  availableToClaim,
  isEnded,
  endTimestampSec,
  onClaim,
  startTimestampSec,
  title,
}: ClaimBlockProps) => {
  return (
    <PaperBox paddingPx={12} alignItems="center" sx={{ paddingBottom: 2.5 }}>
      {!isEnded && endTimestampSec ? (
        <>
          <Typography variant="caption" className="neutral" mb={1.5}>
            {title}
          </Typography>
          <Countdown endMs={endTimestampSec * 1000} startMs={startTimestampSec * 1000} endText="" sx={{ mb: 1.5 }} />
        </>
      ) : null}

      <Typography variant="caption" className="neutral" lineHeight="24px" mb={1}>
        Available to claim
      </Typography>
      <TokenAmount amount={formatWithDots(availableToClaim)} size="medium" mb={2.25} />

      <Button disabled={!+availableToClaim} maxWidth={150} width="100%" onClick={onClaim}>
        Claim
      </Button>
    </PaperBox>
  );
};
