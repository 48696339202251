import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Layout, RouteManager } from 'containers';
import { PersistGate } from 'redux-persist/integration/react';
import { WalletConnectContext } from 'services/wallet-connect';
import { persistor, store } from 'store/configure-store';
import { theme } from 'styles';

import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer autoClose={5200} hideProgressBar position="bottom-right" closeButton />
      <BrowserRouter>
        <Suspense fallback={null}>
          <WalletConnectContext>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Layout>
                <RouteManager />
              </Layout>
            </ThemeProvider>
          </WalletConnectContext>
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
