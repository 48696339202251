import { contracts } from '../contracts';
import { type ChainType, Chains, ContractsNames } from '../types';

export const DEFAULT_TOKEN_DECIMALS = 18;

export const tokens = {
  [Chains.Ethereum]: {
    [ContractsNames.K9Token]: {
      mainnet: {
        address: contracts.K9Token.mainnet.address,
        decimals: DEFAULT_TOKEN_DECIMALS,
        symbol: 'KNINE',
      },
      testnet: {
        address: contracts.K9Token.testnet.address,
        decimals: DEFAULT_TOKEN_DECIMALS,
        symbol: 'KNINE',
      },
    },
  },
} as const;

type TokenContractNames = ContractsNames.K9Token;

export const getTokenInfo = (chain: Chains, contractName: TokenContractNames, network: ChainType) =>
  tokens[chain][contractName][network];
