export const formatTimestampToDateString = (timestamp: number | string) => {
  const date = new Date(+timestamp * 1000);

  // const day = String(date.getDate()).padStart(2, '0');
  // const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  // const year = date.getFullYear();

  // return `${year}-${month}-${day}`;

  const options: Intl.DateTimeFormatOptions = {
    // weekday: 'long', // Full weekday name (e.g., "Saturday")
    year: 'numeric', // Four-digit year (e.g., "2023")
    month: 'long', // Full month name (e.g., "May")
    day: 'numeric', // Day of the month (e.g., "30")
  };

  // console.log(date.toLocaleDateString('en-US')); // Example: "5/30/2023"
  // console.log(date.toLocaleDateString('en-US', options)); // Example: "Saturday, May 30, 2023"
  // console.log(date.toLocaleDateString('hi-IN', options)); // Example (Hindi): "शनिवार, 30 मई 2023"

  // Automatically use the user's preferred locale:
  const formatter = new Intl.DateTimeFormat(undefined, options);

  return formatter.format(date);
};
