import { memo } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { useTimeLeft } from 'lib';
import { COLOR_CHINESE_WHITE, COLOR_REGENT_GRAY, FontWeights } from 'styles';

import { CountdownCell, formatTimeCell } from './countdown.helper';

export interface CountdownProps extends StackProps {
  endMs: number;
  startMs?: number;
  endText: string;
  variant?: 'primary' | 'secondary';
}

export const Countdown = memo(
  ({ endMs, startMs = Date.now(), endText, variant = 'primary', ...stackProps }: CountdownProps) => {
    const timeLeft = useTimeLeft(endMs, startMs);

    if (!timeLeft || (+timeLeft.seconds === 0 && +timeLeft.minutes === 0)) {
      return (
        <Typography variant="h3" className="center">
          {endText}
        </Typography>
      );
    }

    const { days, hours, minutes, seconds } = timeLeft;

    return (
      <Stack direction="row" alignItems="center" spacing={0.5} {...stackProps}>
        <CountdownCell className={variant}>
          <Typography variant="h4" fontWeight={FontWeights.Medium} color={COLOR_CHINESE_WHITE}>
            {formatTimeCell(days)}
          </Typography>
          <Typography variant="body2" color={COLOR_REGENT_GRAY}>
            days
          </Typography>
        </CountdownCell>
        <CountdownCell className={variant}>
          <Typography variant="h4" fontWeight={FontWeights.Medium} color={COLOR_CHINESE_WHITE}>
            {formatTimeCell(hours)}
          </Typography>
          <Typography variant="body2" color={COLOR_REGENT_GRAY}>
            hours
          </Typography>
        </CountdownCell>
        <CountdownCell className={variant}>
          <Typography variant="h4" fontWeight={FontWeights.Medium} color={COLOR_CHINESE_WHITE}>
            {formatTimeCell(minutes)}
          </Typography>
          <Typography variant="body2" color={COLOR_REGENT_GRAY}>
            min.
          </Typography>
        </CountdownCell>
        <CountdownCell className={variant}>
          <Typography variant="h4" fontWeight={FontWeights.Medium} color={COLOR_CHINESE_WHITE}>
            {formatTimeCell(seconds)}
          </Typography>
          <Typography variant="body2" color={COLOR_REGENT_GRAY}>
            sec.
          </Typography>
        </CountdownCell>
      </Stack>
    );
  },
);

Countdown.displayName = 'Countdown';
