import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_S, COLOR_BG, COLOR_BLUE_DUSK, COLOR_WH } from 'styles/theme/variables';

export const getMuiMenuOverrides = (theme: Theme): ComponentsOverrides['MuiMenu'] => ({
  paper: {
    backgroundColor: COLOR_BLUE_DUSK,
    border: 'none',
    borderRadius: BORDER_RADIUS_S,
    marginTop: theme.spacing(1.5),
  },
  list: {
    padding: 0,
    height: 'auto',
  },
});

export const getMuiMenuItemOverrides = (theme: Theme): ComponentsOverrides['MuiMenuItem'] => ({
  root: {
    color: COLOR_WH,
    borderRadius: '4px',
    minHeight: '40px !important',
    padding: theme.spacing(0.5, 0.75),
    '&:focus-visible': {
      background: COLOR_BLUE_DUSK,
    },

    '& + &': {
      marginTop: theme.spacing(0.5),
    },
    '&:hover': {
      background: COLOR_BG,
    },

    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },

    '&.Mui-selected': {
      backgroundColor: COLOR_BG,

      '&:hover': {
        background: COLOR_BG,
      },
    },
  },
});

export const getMuiMenuItemDefaultProps = (): ComponentsProps['MuiMenuItem'] => ({
  disableRipple: true,
});
