import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHAIN_TYPE } from 'app-constants';
import storage from 'redux-persist/lib/storage';
import { Chains } from 'services';
import type { MyPersistConfig, UserState } from 'types';

import {
  initialLyfecycleState,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  updateLifecycleState,
} from '../root';

import { getTokenBalance } from './thunks';

const initialState: UserState = {
  address: '',
  provider: '',
  chainType: CHAIN_TYPE,
  network: Chains.Ethereum,
  tokenPrice: '',
  userBalance: '',
  lifecycleState: initialLyfecycleState,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialState,
      };
    },
  },

  selectors: {
    getUserState: (state) => state,
    getLoadingStatusState: (state) => state.lifecycleState,
    selectIsAuthenticated: (state) => ({ isAuthenticated: !!state.address, address: state.address }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTokenBalance.fulfilled, (state, { payload }) => ({
        ...state,
        userBalance: payload,
      }))
      .addMatcher(isPendingAction, updateLifecycleState)
      .addMatcher(isFulfilledAction, updateLifecycleState)
      .addMatcher(isRejectedAction, updateLifecycleState);
  },
});

export const userPersistConfig: MyPersistConfig<UserState> = {
  key: 'user',
  storage,
  whitelist: ['provider', 'address', 'network', 'chainType'],
};

export const { disconnectWalletState, updateUserState } = userSlice.actions;
export const { getUserState, selectIsAuthenticated, getLoadingStatusState } = userSlice.selectors;

export default userSlice.reducer;
