import { ComponentsOverrides, Theme } from '@mui/material';

import { COLOR_BLUE_DUSK, COLOR_WH_02 } from '../variables';

export const getMuiDividerOverrides = (theme: Theme): ComponentsOverrides['MuiDivider'] => ({
  root: {
    background: COLOR_BLUE_DUSK,
    '&.fullWidth': {
      width: '100%',
    },
    '&.secondary': {
      background: COLOR_WH_02,
    },

    '&.MuiDivider-vertical': {
      height: 'auto',
    },
  },
});
