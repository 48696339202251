import {
  ComponentsOverrides,
  ComponentsProps,
  filledInputClasses,
  inputBaseClasses,
  outlinedInputClasses,
  Theme,
} from '@mui/material';
import {
  BORDER_RADIUS_S,
  COLOR_BLUE_DARK_NAVY,
  COLOR_BLUE_DEEP_INDIGO,
  COLOR_BLUE_DUSK,
  COLOR_BLUE_TWILIGHT,
  COLOR_ORANGE_ACCENT,
  COLOR_WH,
  FontWeights,
} from 'styles/theme/variables';

const TEXTFIELD_BORDER = '1.5px solid';

const textfieldStyles = {
  root: {
    width: '100%',
    background: COLOR_BLUE_DARK_NAVY,
    fontSize: '16px',
    color: COLOR_WH,
    borderRadius: BORDER_RADIUS_S,
    caretColor: COLOR_BLUE_DEEP_INDIGO,
    border: TEXTFIELD_BORDER,
    borderColor: COLOR_BLUE_DUSK,
    boxShadow: 'none',
    overflow: 'hidden',

    '&:hover': {
      background: COLOR_BLUE_DARK_NAVY,
      borderColor: COLOR_BLUE_TWILIGHT,
    },

    '& fieldset': { border: 'none' },
  },
  focused: {
    border: TEXTFIELD_BORDER,
    borderColor: COLOR_ORANGE_ACCENT,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTextFieldOverrides = (theme: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    width: '100%',
    '& input::placeholder': {
      opacity: 1,
      color: COLOR_BLUE_DEEP_INDIGO,
      fontWeight: FontWeights.Regular,
    },

    [`& .${inputBaseClasses.input}`]: {
      padding: theme.spacing(2, 2, 2, 2.5),
      fontSize: '16px',
    },

    [`& .${outlinedInputClasses.input}`]: {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },

      [`&.${inputBaseClasses.disabled}`]: {
        '& + fieldset': {
          borderColor: `${COLOR_BLUE_DUSK} !important`,
        },
      },
    },
    [`& .${outlinedInputClasses.root}`]: {
      height: '100%',

      ...textfieldStyles.root,

      [`&.${inputBaseClasses.focused}`]: {
        ...textfieldStyles.focused,
      },

      [`& .${inputBaseClasses.input}.${inputBaseClasses.disabled}`]: {
        WebkitTextFillColor: COLOR_BLUE_DEEP_INDIGO,
      },
      [`& .${inputBaseClasses.disabled}`]: {
        opacity: 0.55,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLOR_BLUE_DEEP_INDIGO,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '16px',
      marginLeft: 0,
    },

    '& fieldset': { border: 'none' },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  InputProps: {
    style: {},
    autoComplete: 'off',
  },
});

// Styles for amount input with adornments
export const getMuiFilledInputOverrides = (theme: Theme): ComponentsOverrides['MuiFilledInput'] => ({
  root: {
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 'auto',

    ...textfieldStyles.root,

    '&::before': {
      content: 'none',
    },

    '&::after': {
      content: 'none',
    },

    [`&.${inputBaseClasses.focused}`]: {
      ...textfieldStyles.focused,
      color: COLOR_ORANGE_ACCENT,
    },

    [`& .${filledInputClasses.input}`]: {
      padding: 0,
      flex: 1,
      width: '100%',

      height: 28,
      fontSize: 22,
      lineHeight: '28px',
    },
  },
});

export const getMuiFilledInputDefaultProps = (): ComponentsProps['MuiFilledInput'] => ({
  autoComplete: 'off',
});
