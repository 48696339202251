import { Stack, styled } from '@mui/material';

import { BORDER_RADIUS_XS, COLOR_BLUE_DUSK } from '../theme/variables';

export const PaperBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'paddingPx',
})<{
  paddingPx?: number;
}>(({ paddingPx }) => ({
  width: '100%',
  padding: paddingPx ? `${paddingPx}px` : '6px',
  background: COLOR_BLUE_DUSK,
  borderRadius: BORDER_RADIUS_XS,
}));
