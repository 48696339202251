import { alpha, buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import {
  BORDER_RADIUS_XXL,
  BORDER_RADIUS_XXXL,
  COLOR_BLUE_DEEP_INDIGO,
  COLOR_BLUE_DUSK,
  COLOR_WH,
  FontFamilies,
  FontWeights,
  GRADIENT_ACCENT,
  SHADOW_DEFAULT,
  TRANSITION_DEFAULT_TIME,
} from '../variables';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    textAlign: 'center',
    borderRadius: BORDER_RADIUS_XXXL,
    transition: TRANSITION_DEFAULT_TIME,
    padding: theme.spacing(0.75, 1.5),
    fontSize: 13,
    fontWeight: FontWeights.SemiBold,
    fontFamily: FontFamilies.secondary,
    textTransform: 'capitalize',
    boxShadow: SHADOW_DEFAULT,

    '&:hover': {
      boxShadow: SHADOW_DEFAULT,
    },

    '&:active': {
      boxShadow: SHADOW_DEFAULT,
    },
  },

  startIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginRight: '8px',
  },

  endIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginRight: '8px',
  },

  sizeSmall: {
    height: '40px',
  },

  sizeMedium: {
    fontSize: '18px',
    height: '56px',
  },

  sizeLarge: {
    fontSize: '15px',
    height: '60px',
  },

  contained: {
    minWidth: 80,
    background: GRADIENT_ACCENT,

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_WH,
      opacity: 0.5,
    },
  },

  containedSecondary: {
    minWidth: 'auto',
    background: COLOR_BLUE_DUSK,
    boxShadow: 'none',
    borderRadius: BORDER_RADIUS_XXL,

    [`&.${buttonClasses.disabled}`]: {
      background: COLOR_BLUE_DUSK,
      color: alpha(COLOR_WH, 0.68),
    },

    '&:hover': {
      background: COLOR_BLUE_DEEP_INDIGO,
      boxShadow: 'none',
    },
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
  size: 'small',
});
