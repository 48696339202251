import { AsyncThunk } from '@reduxjs/toolkit';

export enum LifecycleStatuses {
  fulfilled = 'fulfilled',
  rejected = 'rejected',
  pending = 'pending',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type PendingAction = ReturnType<GenericAsyncThunk[LifecycleStatuses.pending]>;
export type RejectedAction = ReturnType<GenericAsyncThunk[LifecycleStatuses.rejected]>;
export type FulfilledAction = ReturnType<GenericAsyncThunk[LifecycleStatuses.fulfilled]>;

export interface LifeCycleState {
  lifecycleState: Record<string, string>;
}
