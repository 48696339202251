import { Icon } from 'components';

export const socials = [
  {
    href: 'https://twitter.com/K9finance',
    Icon: Icon.Twitter,
  },
  {
    href: 'https://t.me/k9finance',
    Icon: Icon.Telegram,
  },
];
