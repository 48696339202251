import { forwardRef, PropsWithChildren, ReactNode, Ref } from 'react';
import { Modal, StackProps, Typography } from '@mui/material';
import { COLOR_ACCENT, FontWeights } from 'styles';

import { Wrapper } from './modal.helper';

export type BaseModalProps = {
  onClose?: () => void;
  open: boolean;
};

type ModalWrapperProps = {
  title: ReactNode | string;
  title2?: ReactNode;
} & BaseModalProps &
  Omit<StackProps, 'title'>;

export const ModalWrapper = forwardRef(
  ({ title, open, title2, onClose, children, ...stackProps }: ModalWrapperProps & PropsWithChildren, ref) => {
    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: { xs: 2, sm: 4 },
          '.MuiBackdrop-root': {
            backdropFilter: 'blur(5px)',
          },
        }}
        ref={ref as Ref<HTMLDivElement>}
      >
        <Wrapper {...stackProps}>
          {typeof title === 'string' ? (
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight={FontWeights.SemiBold}
              sx={{ span: { color: COLOR_ACCENT } }}
            >
              {title}
              {title2 && <span> {title2}</span>}
            </Typography>
          ) : (
            title
          )}
          {children}
        </Wrapper>
      </Modal>
    );
  },
);

ModalWrapper.displayName = 'ModalWrapper';
