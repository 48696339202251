export const formatNumber = (number: number | string) => new Intl.NumberFormat().format(+number);

export const formatCurrency = (amount: number | string, locale = 'en-US', currency = 'usd'): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(+amount);

export const formatWithDots = (value: string | number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  if (+value < 1000) {
    return Number(value).toFixed(2);
  }

  const formattedValue = numericValue.toLocaleString('en-GB', {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formattedValue;
};
